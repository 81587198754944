import axios from 'axios';
import { LOAD_CATEGORIES_IN_PROGRESS, LOAD_CATEGORIES, LOAD_CATEGORIES_FAIL,
         UPDATE_CATEGORY_IN_PROGRESS, UPDATE_CATEGORY_OK, UPDATE_CATEGORY_FAIL,
        DELETE_CATEGORY_IN_PROGRESS, DELETE_CATEGORY_OK, DELETE_CATEGORY_FAIL,
        DELETE_CATEGORY_RESET, ADD_CATEGORY_IN_PROGRESS, ADD_CATEGORY_OK, 
        ADD_CATEGORY_FAIL} 
        from './types';
import Localization from './../../helpers/Localization';
import UtilsHelper from '../../helpers/UtilsHelper';

/**
 * 
 * Chargement des cateogies
 * 
 */
export const loadCategories = (language='fr') => async dispatch => {

    try {

        dispatch({
            type: LOAD_CATEGORIES_IN_PROGRESS
        });
        
        let categories = await axios.get(UtilsHelper.getFunctionUrl("URL_CLOUD_CATEGORIES"));

        // test s'il-y-a des catégories (normalement celles par défaut [Nouveatés, Tout, etc.....])
        // => si c'est vide on crée les catégories par défaut (sert pour base de test firestore local)
        if (categories && categories.data.length === 0) {
            await axios.get(UtilsHelper.getFunctionUrl("URL_CLOUD_CATEGORIES_INIT"));
            categories = await axios.get(UtilsHelper.getFunctionUrl("URL_CLOUD_CATEGORIES"));
        }
        
        dispatch({
            type: LOAD_CATEGORIES,
            payload: {
                language: language,
                categories: categories.data
            }
        });

    } catch (error) {

        console.error('error loadCategories = ', error);

        dispatch({
            type: LOAD_CATEGORIES_FAIL
        });
    }
}

/**
 * 
 * Ajout catégorie
 * 
 * @param {*} category 
 */
export const addCategory = (category, language = 'fr') => async dispatch => {

    dispatch({type: ADD_CATEGORY_IN_PROGRESS});

    try {

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
            
        const body =  JSON.stringify(category);

        const categoryAdded = await axios.post(UtilsHelper.getFunctionUrl("URL_CLOUD_ADD_CATEGORY"), body, config);

        dispatch({
            type: ADD_CATEGORY_OK,
            payload: {
                category: categoryAdded.data,
                language: language
            }
        });
        
    } catch (error) {

        console.error('error addCategory = ', error.response.data.message);

        dispatch({
            type: ADD_CATEGORY_FAIL,
            payload: Localization.getTextLocalization(error.response.data.message, language)
        });        
    }
}

/**
 * 
 * Mise à jour de la Category
 * 
 * @param {*} category 
 */
export const updateCategory = category => async dispatch => {

    try {
        
        dispatch({
            type: UPDATE_CATEGORY_IN_PROGRESS,
            payload: category._id
        });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body =  JSON.stringify(category);

        await axios.post(UtilsHelper.getFunctionUrl("URL_CLOUD_UPDATE_CATEGORY"), body, config);
        
        dispatch({
            type: UPDATE_CATEGORY_OK,
            payload: category
        });

    } catch (error) {

        console.error('error updateCategory = ', error);

        dispatch({
            type: UPDATE_CATEGORY_FAIL
        });
    }

}

/**
 * 
 * Suppression d'une catégorie
 * 
 * @param {*} idCategory 
 */
export const deleteCategory = idCategory => async dispatch => {

    dispatch({
        type: DELETE_CATEGORY_IN_PROGRESS,
        payload: idCategory
    });

    try {
        
        await axios.post(UtilsHelper.getFunctionUrl("URL_CLOUD_DELETE_CATEGORY"), {id: idCategory});

        dispatch({
            type: DELETE_CATEGORY_OK,
            payload: idCategory
        });

    } catch (error) {

        console.error('error delete category = ', error);

        dispatch({
            type: DELETE_CATEGORY_FAIL
        });
    }
}

/**
 * 
 * Réintialisation du flag delete 
 * Nécessaire si plusieurs delete à la suite
 * 
 */
export const resetFlagDelete = () => dispatch => {
    dispatch({
        type: DELETE_CATEGORY_RESET
    });
}