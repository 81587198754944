import React, {Fragment, useRef} from 'react';
import { connect } from 'react-redux';
import LocalizationHelper from '../../helpers/LocalizationHelper';
import Localization from '../../helpers/Localization';
import { searchProducts } from '../../redux/actions/productAction';
import '../../css/search.css';

let clickSearch = false;
let clickInit = false;

const Search = ({
    manage,
    language, 
    products,
    searchProductsInProgress, 
    searchProducts
}) => {

    const inputEl = useRef(null);

    const searchProductsKeywords = () => {

        clickSearch = true;
        clickInit = false;
        searchProducts(inputEl.current.value, products, language, manage);
    }
    
    const initSearch = () => {
        
        clickSearch = false;
        clickInit = true;
        inputEl.current.value = "";
        searchProducts("", products, language, manage);
    }
    

    return (
    <Fragment>
        <nav className="navbar navbar-light bg-light">
            <form className="form-inline mx-auto">
                <div className="row">
                    <div className="col-7 mr-sm-2 customInput">
                        <input ref={inputEl} className="form-control" type="search" placeholder={Localization.getTextLocalization("shopKeywords", language)} aria-label="Search" />
                    </div>
                    <div className="col-4">
                        <button onClick={searchProductsKeywords} className="btn btn-sm btn-outline-info" type="button">
                            <div className="spinnerSeach">
                                <LocalizationHelper text="shopSearch"/>
                                {searchProductsInProgress && clickSearch && (
                                    <img className="spinner" src={require('../../img/spinner.gif')} alt="" />
                                )}
                            </div>
                        </button>
                        <button onClick={initSearch} className="mt-2 btn btn-sm btn-outline-danger" type="button">
                            <div className="spinnerSeach">
                                <LocalizationHelper text="shopSearchReset"/>
                                {searchProductsInProgress && clickInit && (
                                    <img className="spinner" src={require('../../img/spinner.gif')} alt="" />
                                )}
                            </div>
                        </button>
                    </div>
                </div>
            </form>
        </nav>
        <div className="text-center text-muted">
        <small>
          <LocalizationHelper text="shopKeywordsSeparator"/>
        </small>
      </div>
    </Fragment>
    )
}

const mapStateToProps = state => ({
    searchProductsInProgress: state.products.searchProductsInProgress
});

export default connect(mapStateToProps, {searchProducts})(Search);
