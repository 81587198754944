import React, {useState, useEffect, Fragment} from 'react';
import { connect } from 'react-redux';
import LocalizationHelper from '../../../helpers/LocalizationHelper';
import Localization from '../../../helpers/Localization';
import { addToCart, removeFromCart } from '../../../redux/actions/shoppingCartAction';
import { NavLink } from 'react-router-dom';
import '../../../css/product-item.css'

function ProductItemShopFooter({
    language,
    activeCart,
    cartProducts,
    product,
    addToCart,
    removeFromCart
}) {

    const [showAddCartProduct, setShowAddCartProduct] = useState(true);

    useEffect(() => {

        cartProducts.forEach(cartProduct => {
          if (product._id === cartProduct._id) {
            setShowAddCartProduct(false);
            return;
          }
        })
        
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [showAddCartProduct]);

    return (

        <Fragment>
          
            <div style={{position: "absolute", bottom: "0rem", width: "100%"}}>
                <div className="button-container">
                    <button  className="btn btn-outline-info mb-4" >
                        <NavLink to={`/contact`} className="contact-link" style={{ textDecoration: "none" }}>
                            <LocalizationHelper text="productContactUs"/>
                        </NavLink>
                    </button>
                    {activeCart && 
                        <button style={{right:"1rem", backgroundColor: "transparent", border:"none"}} className="btn btn-success mb-4 mr-3 button-shopping" >
                        
                        {showAddCartProduct ? 
                            <img onClick={() => {addToCart(product);setShowAddCartProduct(false)}} className='shopping-icon' src={require('../../../img/icons/shopping-basket-add-icon.png')} alt="" title={Localization.getTextLocalization("addToCart", language)} />
                            :
                            <img onClick={() => {removeFromCart(product); setShowAddCartProduct(true)}} className='shopping-icon' src={require('../../../img/icons/shopping-basket-remove-icon.png')} alt={Localization.getTextLocalization("removeFromCart", language)} />
                        }  
                        
                        </button>                          
                    }                     
                </div>
            </div>

        </Fragment>
    )
}

const mapStateToProps = state => ({
    activeCart: state.shoppingCart.activeCart,
    cartProducts: state.shoppingCart.products,
    language: state.config.language
  });


export default connect(mapStateToProps, {addToCart, removeFromCart})(ProductItemShopFooter);
