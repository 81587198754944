import {combineReducers} from 'redux';
import configReducer from './configReducer';
import sendMailReducer from './sendMailReducer';
import productReducer from './productReducer';
import categoryReducer from './categoryReducer';
import shoppingCartReducer from './shoppingCartReducer';
import userReducer from './userReducer';

export default combineReducers({
    config: configReducer,
    sendMail: sendMailReducer,
    products: productReducer,
    categories: categoryReducer,
    shoppingCart: shoppingCartReducer,
    user: userReducer
});