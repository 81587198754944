export const constants = {

    /** AUTRE */

    ACTIVE_CART: process.env.NODE_ENV === 'development',

    CAPTCHA_KEY: '6LceKacUAAAAAI1tyGJJMVwPNWUjRf-F_Y7JWFgn',

    URL_CORS_PROXY: 'https://cors-anywhere.herokuapp.com/',

    UPDATE_DELAY_TIME: 300,

    EMULATORS: false,

    /** CLOUD FUNCTIONS */

    /** USER */

    URL_CLOUD_USER_REGISTER: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreRegisterUser',
    URL_CLOUD_USER_REGISTER_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreRegisterUser',

    URL_CLOUD_USER_DELETE: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreDeleteUser',
    URL_CLOUD_USER_DELETE_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreDeleteUser',
    
    URL_CLOUD_USER_UPDATE: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreUpdateUser',
    URL_CLOUD_USER_UPDATE_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreUpdateUser',
    
    URL_CLOUD_GET_USER_INFOS: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreGetUserInfos',
    URL_CLOUD_GET_USER_INFOS_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreGetUserInfos',

    URL_CLOUD_ADDRESS_LOCATIONS: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreGetAddressLocations',
    URL_CLOUD_ADDRESS_LOCATIONS_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreGetAddressLocations',

    /** MAIL */

    URL_CLOUD_MAIL: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/httpEmail',  
    URL_CLOUD_MAIL_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/httpEmail ',


    /** CATEGORY */
    
    URL_CLOUD_CATEGORIES_INIT: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreInitCategories',
    URL_CLOUD_CATEGORIES_INIT_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreInitCategories',

    URL_CLOUD_CATEGORIES: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreGetCategories',
    URL_CLOUD_CATEGORIES_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreGetCategories',

    URL_CLOUD_ADD_CATEGORY: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreAddCategory',
    URL_CLOUD_ADD_CATEGORY_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreAddCategory', 

    URL_CLOUD_UPDATE_CATEGORY: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreUpdateCategory',
    URL_CLOUD_UPDATE_CATEGORY_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreUpdateCategory',
    
    URL_CLOUD_DELETE_CATEGORY: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreDeleteCategory',
    URL_CLOUD_DELETE_CATEGORY_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreDeleteCategory',


    /** PRODUCT */

    URL_CLOUD_ADD_PRODUCT: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreAddProduct',
    URL_CLOUD_ADD_PRODUCT_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreAddProduct',

    URL_CLOUD_UPDATE_PRODUCT: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreUpdateProduct',
    URL_CLOUD_UPDATE_PRODUCT_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreUpdateProduct',
   

    URL_CLOUD_UPDATE_CHECKBOX_PRODUCT: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreUpdateCheckboxProduct',
    URL_CLOUD_UPDATE_CHECKBOX_PRODUCT_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreUpdateCheckboxProduct',

    URL_CLOUD_FETCH_PRODUCT: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreFetchProducts',
    URL_CLOUD_FETCH_PRODUCT_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreFetchProducts',

    URL_CLOUD_DELETE_PRODUCT: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreDeleteProduct',
    URL_CLOUD_DELETE_PRODUCT_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreDeleteProduct',

     
    URL_CLOUD_FETCH_PRODUCT_BY_ID: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/firestoreFetchProductByID',
    URL_CLOUD_FETCH_PRODUCT_BY_ID_EMULATOR: 'http://localhost:3000/jacquesmarieshop/us-central1/firestoreFetchProductByID',
    
    URL_CLOUD_SEARCH_PRODUCT: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/searchProducts',
    //URL_CLOUD_SEARCH_PRODUCT: 'http://localhost:3000/jacquesmarieshop/us-central1/searchProducts',


    /** MESSAGE */


    URL_CLOUD_SAVE_MESSAGE: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/saveMessage',
    //URL_CLOUD_SAVE_MESSAGE: 'http://localhost:3000/jacquesmarieshop/us-central1/saveMessage',

    URL_CLOUD_GET_MESSAGES: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/getMessages',

    URL_CLOUD_DELETE_MESSAGE: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/deleteMessage',

    URL_CLOUD_UPLOAD_FILE: 'https://us-central1-jacquesmarieshop.cloudfunctions.net/uploadFile',
    //URL_CLOUD_UPLOAD_FILE: 'http://localhost:3000/jacquesmarieshop/us-central1/uploadFile',


}