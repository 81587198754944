import React, {useState, Fragment, useEffect} from 'react';
import Modal from "react-animated-modal";
import '../../css/register.css';
import { connect } from 'react-redux';
import LocalizationHelper from '../../helpers/LocalizationHelper';
import Localization from '../../helpers/Localization';
import { registerUser, resetRegisterUser} from './../../redux/actions/userAction';
import { fetchAdressLocations } from '../../redux/actions/configAction';
import $ from 'jquery';

function Register({
    visible, 
    language,
    closeModalCallback,
    registerUser,
    resetRegisterUser,
    registerInProgress,
    registerOk,
    registerFailed,
    message,
    fetchAdressLocations,
    addressLocations
}) {

    const [formData, setFormData] = useState({
        email: '',
        name: '',
        firstName: '',
        address: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        isValidForm: true,
        profile: 'admin',
        message: '',
        addressLocation: 'france'
    });

    useEffect(() => {

        fetchAdressLocations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchAdressLocations]);

    const onChange = e => {
        
        //let validForm = false;

        setFormData({...formData, [e.target.name]: e.target.value});
        /*console.log("formdata = ", formData);
        if (formData.email.trim() !== '' && 
            formData.name.trim() !== '' && 
            formData.firstName.trim() !== '' && 
            formData.address.trim() !== '' && 
            formData.password.trim() !== '' && 
            formData.confirmPassword.trim() !== ''  &&
            formData.password.trim() === formData.confirmPassword.trim()
            ) {

                validForm = true;
            }*/

            //setFormData({...formData, isValidForm: validForm});
    }

    /**
     * 
     * Fadeout sur modal et reset du register ok
     * 
     */
    const fadeOutModal = () => {

        setTimeout(() => {

            $('#registerModal').addClass('registerFadeOut');
            
            setTimeout(() => {
                resetRegisterUser();
                closeModalCallback();
            }, 1800);
            
        }, 1000)
          
    }
    /**
     * 
     * Fonction de rendu de la liste des catégories
     * 
     */
    const renderAddressLocations = () => {

        if (addressLocations) {
            return addressLocations.map((adressLocation, index) => {
                return <option key={index} value={adressLocation._id}>
                    {language === 'fr' && adressLocation.nameFR}
                    {language === 'en' && adressLocation.nameEN}
                </option>
            });
        }

    }
    /**
     * 
     * Sauvegarde du user
     * 
     * @param {*} e 
     */
    const onSubmit = e => {

        e.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            setFormData({...formData, isValidForm: false, message: Localization.getTextLocalization('registerPassworError', language)});
            return;
        } else {
            setFormData({...formData, isValidForm: true, message: ''});
        }
        
        console.log("formdata = ", formData);

        registerUser(formData, language);
  
    }

    return (
        <div id="registerModal">
            <Modal visible={visible} closemodal={() => closeModalCallback()}>    
                <form className="form" onSubmit={e => onSubmit(e)}>
                    <div className="register form-group">

                        <div className="register-title mt-2 pl-2">
                            <LocalizationHelper text="registerCreateAccount"/>
                            <div className="register-sub-title mb-1">
                                <span style={{color: "#E55400"}} className="register-label ml-1">*</span> {' '}
                                <LocalizationHelper text="registerAllFields"/>
                            </div>
                        </div>
                        

                        <span className="register-label ml-2 mt-2">
                            <LocalizationHelper text="registerEmail"/>
                        </span>
                        <span style={{color: "#E55400"}} className="register-label ml-1">*</span>
                        <div className="d-flex flex-row justify-content-center">
                            <input  required type="email" className="form-control ml-2 mt-1 register-input" 
                                    name="email"
                                    value={formData.email} 
                                    onChange={e => onChange(e)}
                                    minLength="4"
                                    maxLength="60"/>
                        </div>

                        <span className="register-label ml-2 mt-1">
                            <LocalizationHelper text="registerName"/>
                        </span>
                        <span style={{color: "#E55400"}} className="register-label ml-1">*</span>
                        <div className="d-flex flex-row justify-content-center">
                            <input  required type="text" className="form-control ml-2 mt-1 register-input" 
                                    name="name"
                                    value={formData.name} 
                                    onChange={e => onChange(e)}
                                    minLength="2"
                                    maxLength="50"/>
                        </div>

                        <span className="register-label ml-2 mt-1">
                            <LocalizationHelper text="registerFirstName"/>
                        </span>      
                        <span style={{color: "#E55400"}} className="register-label ml-1">*</span>
                        <div className="d-flex flex-row justify-content-center">
                            <input  required type="text" className="form-control ml-2 mt-1 register-input" 
                                    name="firstName"
                                    value={formData.firstName} 
                                    onChange={e => onChange(e)}
                                    minLength="2"
                                    maxLength="50"/>
                        </div>
                                
                        <span className="register-label ml-2 mt-1">
                            <LocalizationHelper text="registerAddress"/>
                        </span>

                        <span style={{color: "#E55400"}} className="register-label ml-1">*</span>
                        {addressLocations && addressLocations.length !==0 && (
                            <select required className="ml-4 mt-2 mb-2 option-label" name="addressLocation" value={formData.addressLocation} onChange={e => {onChange(e);}}>
                                {renderAddressLocations()}
                            </select>
                        )}

                        <div className="d-flex flex-row justify-content-center">
                            <textarea  className="form-control ml-2 mt-1 register-input" 
                                        name="address"
                                        rows="1"
                                        value={formData.address} 
                                        onChange={e => onChange(e)}
                                        minLength="2"
                                        maxLength="150"/>
                        </div>

                        <span className="register-label ml-2 mt-1">
                            <LocalizationHelper text="registerPhoneNumber"/>
                        </span>
                        <div className="d-flex flex-row justify-content-center">
                            <input type="tel" className="form-control ml-2 mt-1 register-input" 
                                name="phoneNumber"
                                value={formData.phoneNumber} 
                                onChange={e => onChange(e)}
                                minLength="6"
                                maxLength="16"
                            />
                        </div>

                        <span className="register-label ml-2 mt-2">
                            <LocalizationHelper text="registerPassword"/>
                        </span>
                        <span style={{color: "#E55400"}} className="register-label ml-1">*</span>
                        <div className="d-flex flex-row justify-content-center">
                            <input  required type="password" className="form-control ml-2 mt-1 register-input" 
                                    name="password"
                                    value={formData.password} 
                                    onChange={e => onChange(e)}
                                    minLength="6"
                                    maxLength="20"/>
                        </div>


                        <span className="register-label ml-2 mt-1">
                            <LocalizationHelper text="registerConfirmPassword"/>
                        </span>
                        <span style={{color: "#E55400"}} className="register-label ml-1">*</span>
                        <div className="d-flex flex-row justify-content-center">
                            <input  required type="password" className="form-control ml-2 mt-1 register-input" 
                                    name="confirmPassword"
                                    value={formData.confirmPassword} 
                                    onChange={e => onChange(e)}
                                    minLength="6"
                                    maxLength="20"/>
                        </div>

                        {!formData.isValidForm && 

                            <div className="d-flex flex-row justify-content-center mt-3 register-error-message">
                                {formData.message}
                            </div>
                        }

                        {registerInProgress &&

                            <div className="d-flex flex-row justify-content-center mt-3">
                                <span className="callCloudFunctionMessageOK blink_me">
                                    <LocalizationHelper text="registerInProgress"/> 
                                </span>
                                <img className="spinner" src={require('../../img/spinner.gif')} alt="" />
                            </div>    
                        }
                                

                        {!registerInProgress && registerOk &&

                            <div className="d-flex flex-row justify-content-center mt-3 register-ok-message">
                                {message}
                            </div>
                        }

                        {!registerInProgress && registerFailed &&

                            <div className="d-flex flex-row justify-content-center mt-3 register-error-message">
                                <span dangerouslySetInnerHTML={{ __html: message }} />
                            </div>
                        }

                        <div className="d-flex flex-row justify-content-around mt-3">
                            <button disabled={(registerInProgress || registerOk) ? 'disabled' : ''}  type="submit" className="btn btn-success my-1">
                                <LocalizationHelper text="register"/>    
                            </button>
                            <button disabled={(registerInProgress || registerOk)? 'disabled' : ''}  onClick={closeModalCallback} type="button" className="btn btn-danger my-1">
                                <LocalizationHelper text="cancel"/>    
                            </button>
                        </div>
                    </div>
                </form> 
            </Modal>
            {!registerInProgress && registerOk && (
                        
                <Fragment>
                    {fadeOutModal()}
                </Fragment>
            )}
        </div>
    )
};

const mapStateToProps = state => ({
    language: state.config.language,
    registerInProgress: state.user.registerInProgress,
    registerOk: state.user.registerOk,
    registerFailed: state.user.registerFailed,
    message: state.user.message,
    addressLocations: state.config.addressLocations
});

export default connect(mapStateToProps, 
    {registerUser, resetRegisterUser, fetchAdressLocations})(Register);
