import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

function ProtectedRoutes({component: Component, ...rest}) {

    const {path} = rest;
    const {isAuthenticated} = rest;
    const {setShowLogin} = rest;

    if( !isAuthenticated ){
              
        setShowLogin(true);
        return <Redirect to="/home" />

      } else {

        setShowLogin(false);
        return (
            <Route exact path={path} component={Component} />
          )
      }
    
}

const mapStateToProps = state => ({
    isAuthenticated: state.user.isAuthenticated
}); 

export default connect(mapStateToProps, {})(ProtectedRoutes);
