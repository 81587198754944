import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import '../../css/cart-hover.css';
import LocalizationHelper from './../../helpers/LocalizationHelper';
import UtilsHelper from './../../helpers/UtilsHelper';

function CartHover({ products, language }) {

    const renderProducts = () => {

        let totalPrice = 0;
        const priceCurrency = language === 'fr' ? ' €' : ' $';

        return products.map((product, index) => {

            const title = language === 'fr' ? product.titleFR : product.titleEN;
            const description = language === 'fr' ? product.descriptionFR : product.descriptionEN;
            const price = language === 'fr' ? product.priceEuro  : product.priceDollar;
            totalPrice += language === 'fr' ? Number.parseInt(product.priceEuro) : Number.parseInt(product.priceDollar);

            return (
                <Fragment key={product._id} >
                    <div className="row align-items-center os-animation fadeInUpSpeed">
                        <div id="cartHoverImage" className="col-3 cart-hover-image-container os-animation fadeInLeftSpeed">
                            <img className="mr-1 p-2 cart-hover-image" src={product.imageUrl[product.firstImageIndex]} alt=""></img>
                            <div className="d-flex colum cart-hover-description">
                                <span className="col-7" dangerouslySetInnerHTML={{ __html: description }} />
                                <img className="col-5 m-2 p-2 cart-hover-image-description" src={product.imageUrl[product.firstImageIndex]} alt=""></img>
                            </div>
                        </div>
                        <div style={{zIndex: "1000"}} className="col-9 column align-items-end">
                            <div>
                                <p className="cart-hover-title">{title}</p>
                            </div>
                            <div>
                                <p className="cart-hover-price"><NumberFormat value={price} displayType={'text'} format={UtilsHelper.getNumberMaskFormat(price)} /> {priceCurrency}</p>
                            </div>
                        </div>
                    </div>
                    <div className="cart-hover-separator"></div>
                    {index === products.length - 1 && (
                    <div className="row align-items-center os-animation fadeInUpSpeed">
                        <div className="col-3">
                            
                        </div>
                        <div className="row col-9 justify-content-end">
                            <div>
                                <p className="cart-hover-total"><LocalizationHelper text="cartHoverTotal" /></p>
                            </div>
                            <div>
                                <p className="cart-hover-total-price"><NumberFormat value={totalPrice} displayType={'text'} format={UtilsHelper.getNumberMaskFormat(totalPrice)} /> {priceCurrency}</p>
                            </div>
                        </div>
                    </div>
                    )}
                </Fragment>
            )
        })
    }

    return (
        <div className="cart-hover">
            {renderProducts()}
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.shoppingCart.products,
    language: state.config.language,
});

export default connect(mapStateToProps, {})(CartHover);
