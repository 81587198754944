import React, {useEffect, useState, Fragment} from 'react';
import '../../../css/admin.css';
import '../../../css/add-product.css';
import LocalizationHelper from '../../../helpers/LocalizationHelper';
import Localization from '../../../helpers/Localization';
import { updateProduct, fetchProductByID } from '../../../redux/actions/productAction';
import { loadCategories } from '../../../redux/actions/categoryAction';
import { connect } from 'react-redux';
import Resizer from 'react-image-file-resizer';
import ModalProductPreview from './ModalProductPreview';
import EditorWrapper from '../../layout/EditorWrapper';
import { constants } from '../../../configuration/constants';
import UtilsHelper from '../../../helpers/UtilsHelper';
import $ from 'jquery';

const URL_CORS_PROXY = constants.URL_CORS_PROXY;
let imageUrlToDelete;
let modifImage = false;

const EditProduct = ({categories,
                     productId,
                     editedProduct, 
                     processUpdateInProgress,
                     processUpdateOk,
                     processUpdateFailed, 
                     language,
                     loadCategories,
                     fetchProductByID,
                     updateProduct}) => {
  
    // ref sur le input images qui est remplacé par un bouton
    let fileInputRef;
    let width = window.innerWidth;
    let topMargin = '0';

    const titleFR_Init = editedProduct ? editedProduct.titleFR : '';

    useEffect(() => {

        if (productId != null) {

            const fetchData = async () => {

                await fetchProductByID(productId);
                await loadCategories(language);

                if (editedProduct && editedProduct.imageUrl) {

                    /** gestion des images */
                    // - utilisation CORS PROXY pour charger chaque image du bucket
                    // - pour chaque image on reconstruit l'objet File
                    // - count sert à savoir quand on a traité toues les images pour
                    // savoir quand mettre à jour le state
                    const files = [];
                    const numberImage = editedProduct.imageUrl.length;
                    let count = 0;
                    editedProduct.imageUrl.forEach(async url => {
                        
                        await fetch(URL_CORS_PROXY + url)
                              .then((resp) => resp.blob())
                              .then(function(data) {
                              const imageInfos = UtilsHelper.getImageInfos(url);
                              files.push(new File([data], imageInfos.name + '.' + imageInfos.extension, {type: `image/${imageInfos.extension}`, lastModified: Date.now()}));
                              count += 1;
                              if (count === numberImage) {
                                imageUrlToDelete = editedProduct ? editedProduct.imageUrl : [];
                                const filesSort = [];
                                editedProduct.imageUrl.forEach(image => {
                                    files.forEach(file => {
                                        const fileName = file.name;
                                        if (image.includes(fileName)) {
                                            filesSort.push(file);
                                        }
                                    })
                                })
 
                                setSelectedImage({
                                    selectedImage: filesSort,
                                    files: editedProduct ? editedProduct.imageUrl : []
                                });
                                formData.imageUrl = editedProduct.imageUrl;
                              }
                          })
                          .catch(function(error) {
                            console.log(error);
                          }); 
        
                    });

                }

                setFormData({
                    _id: editedProduct ? editedProduct._id : '',
                    category: editedProduct && editedProduct.category  ? editedProduct.category.idCat : '',
                    titleFR: titleFR_Init,
                    titleEN: editedProduct ? editedProduct.titleEN : '',
                    descriptionFR: editedProduct ? editedProduct.descriptionFR : '',
                    descriptionEN: editedProduct ? editedProduct.descriptionEN : '',
                    priceEuro: editedProduct ? editedProduct.priceEuro: 0,
                    priceDollar: editedProduct ? editedProduct.priceDollar : 0,
                    active: editedProduct ? editedProduct.active : false,
                    sold: editedProduct ? editedProduct.sold : false,
                    news: editedProduct ? editedProduct.news : false,
                    imageUrl: editedProduct ? editedProduct.imageUrl : '',
                    firstImageIndex: editedProduct ? editedProduct.firstImageIndex : 0,
                    validForm: true,
                });

              }
        
              fetchData();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchProductByID, loadCategories, productId, titleFR_Init]);

    /** State des données du formulaire */
    const [formData, setFormData] = useState({
        category: '',
        titleFR: '',
        titleEN: '',
        descriptionFR: '',
        descriptionEN: '',
        priceEuro: 0,
        priceDollar: 0,
        active: true,
        sold: false,
        news: false,
        reserved: false,
        imageUrl: [],
        firstImageIndex: 0,
        validForm: false,
        imageUrlToDelete: []
    });

     /** State des images sélectionnées et à uploader */
    const [selectedImage, setSelectedImage] = useState({
        selectedImage: [],
        files: []
    });

    const [showModal, setShowModal] = useState(false);
    const [positionYpreview, setPositionYpreview] = useState(0);

    const {category, titleFR, titleEN, priceEuro,
           priceDollar, active, sold, news, reserved, firstImageIndex} = formData;

    const {fileImage, files} = selectedImage;

    /**
     * 
     * Gestion du changement valeurs dans formulaire
     * 
     * @param {*} e 
     */
    const onChange = e => {
        const validFormVerif = isFormValid(e);

        if (e.target.name === "imageUrlCurrent") {
            setFormData({...formData, [e.target.name]: e.target.value, imageUrl: [e.target.value, ...formData.imageUrl]});
        } else {
            setFormData({...formData, [e.target.name]: e.target.value, validForm: validFormVerif });
        }
    }

    const onChangeDescriptionFR = value => {

        setFormData({...formData, descriptionFR: value});
    }

    const onChangeDescriptionEN = value => {

        setFormData({...formData, descriptionEN: value});
    }

    /**
     * 
     * Détermine si une catégorie a été sélectionnée
     * 
     * @param {*} e 
     */
    const isFormValid = (e) => {

        if ((e.target.name === "category")) {
            if (e.target.value.toString() !== "0") {
                return true;
            } else {
                return false;
            }
        } 
        return formData.validForm;
    }

    /**
     * 
     * Sauvegarde du product
     * 
     * @param {*} e 
     */
    const onSubmit = e => {

        e.preventDefault();

        let categorySelected = categories.find(cat => cat.idCat.toString() === formData.category.toString());
        if (!categorySelected) {
            categorySelected = categories.find(cat => cat._id.toString() === formData.category.toString());
        }
        formData.category = categorySelected._id;
        if (!modifImage) {
            formData.imageUrl = selectedImage.selectedImage;
        }
        formData.imageUrlToDelete = imageUrlToDelete;

        updateProduct(formData);
    }

    /**
     * 
     * Fonction de rendu de la liste des catégories
     * 
     */
    const renderCategory = () => {

        if (categories) {
            return categories.filter(cat => cat.idCat >= 0).map(category => {
                return <option key={category.idCat} value={category.idCat}>
                    {language === 'fr' && category.nameFR}
                    {language === 'en' && category.nameEN}
                </option>
            });
        }

    }

    /**
     * 
     * Charge les images sélectionnées dans le state et dans l'ihm
     * 
     * @param {*} e 
     */
    const loadImage = e => {

        const filesURL = [];
        const filesToAdd = [];
        const filesTargetArray = Array.from(e.target.files);
        const originalFiles = selectedImage.selectedImage;
        const nbrFiles = filesTargetArray.length;
        let count = 0;

        filesTargetArray.forEach(fileAdd => {
            
            Resizer.imageFileResizer(
                fileAdd,
                800,
                800,
                'jpeg',
                70,
                0,
                uri => {

                    count += 1;

                    const fileResize = new File([uri], fileAdd.name, {
                        type: 'image/jpeg',
                        lastModified: Date.now()
                    });

                    filesURL.push(URL.createObjectURL(fileAdd));
                    filesToAdd.push(fileResize);

                    if (count === nbrFiles) {

                        let filesToAddSort = [];
                        let filesUrlSort = [];
                        filesTargetArray.forEach(targetImage => {
                            filesToAdd.forEach((imageToAdd, index) => {
                                if (imageToAdd.name === targetImage.name) {
                                    filesToAddSort.push(imageToAdd); 
                                    filesUrlSort.push(filesURL[index]);
                                }
                            });
                        });

                        modifImage = true;
                        formData.imageUrl = [...selectedImage.selectedImage, ...filesToAddSort];
                        setSelectedImage({
                            selectedImage: [...selectedImage.selectedImage, ...filesToAddSort],
                            files: [...selectedImage.files, ...filesUrlSort]
                        });
                    }
                    
                },
                'blob'
            );
            
        });

    }

    /**
     * 
     * Click sur bouton "Supprimer" d'une image
     * 
     * @param {*} e 
     * @param {*} index 
     */
    const deleteImage = (e, index)=> {

        e.preventDefault();


        if (formData.firstImageIndex > index) {
            formData.firstImageIndex -= 1;
        }

        const arraySelectedImages = Array.from(selectedImage.selectedImage);
        const selectedImageResult = [];
        arraySelectedImages.forEach((image, i) => {
            if (i !== index) {
                selectedImageResult.push(image);
            }
        });

        const filesUrlResult = [];
        selectedImage.files.forEach((fileUrl, i) => {
            if (i !== index) {
                filesUrlResult.push(fileUrl);
            }
        });
        formData.imageUrl = [...selectedImageResult];
        modifImage = true;
        setSelectedImage({
            selectedImage: selectedImageResult,
            files: filesUrlResult
        });

    }

    /**
     * 
     * Fonction de rendu de la liste des images sélectionnées
     * 
     */
    const renderImages = () => {

        if (files && files.length > 0) {
            return files.map((file, index) => {
                return (
                    <Fragment key={index} >
                        <div className="images-container">
                            <img className="file-image mt-3 ml-4" src={file} alt=""/>
                            <button onClick={(e) => deleteImage(e, index)} 
                                    className="btn btn-danger ml-3 mt-4 mb-4">
                                <LocalizationHelper text="delete"/>
                            </button>
                            <label className="ml-3 text-muted small">
                                <LocalizationHelper text="addProductFirstImage"/>
                            </label>
                            <input
                                className="ml-2"
                                type='radio'
                                value={index}
                                name='image'
                                key={index}
                                checked = {formData.firstImageIndex === index}
                                onChange={() => {
                                    setFormData({...formData, firstImageIndex: index});
                                    formData.firstImageIndex = index;
                                }}
                            />
                        )}
                        </div>
                    </Fragment>
                )
            });
        }
    }

    /**
     * 
     * Fermeture modal preview
     * 
     */
    const closeModalCallback = () => {
        setShowModal(false);
    }

    /**
     * 
     * Calcul de la marge top suivant largeur device
     * 
     */
    const calculateTopMargin = () => {

        if (width < 500) {

            const value = 65 + (selectedImage.files.length - 1) * 30;
            topMargin = value.toString() + 'rem';
        }
    }

    return (
    <Fragment>
        {calculateTopMargin()}
        <div className="modal-preview" style={{top: width < 500 ? `${positionYpreview}px` : topMargin}}>
            <span style={{display: showModal ? 'block': 'none'}}>
                <ModalProductPreview 
                    visible={showModal} 
                    language={language}
                    productDatas={formData}
                    fileImage={files[firstImageIndex]}
                    filesImages={files}
                    closeModalCallback={closeModalCallback}/>
            </span>
        </div>
        <div className="os-animation fadeInLeftSpeed mt-3 mb-4">
            <p className="text-header-form" >
                <LocalizationHelper text="adminEditProduct"/>
            </p>
            <form className="form" onSubmit={e => onSubmit(e)}>
                <div className="form-group">
                    {categories && categories.length !==0 && (
                        <select name="category" value={category} onChange={e => {onChange(e);}}>
                            {renderCategory()}
                        </select>
                    )}
                    { (!categories || categories.length) ===0 && (
                        <img src={require('../../../img/spinner.gif')} alt="" width="32px"/> 
                    
                    )}
                </div>
                <div className="form-group">
                    <input   
                            style={{display: 'none'}}
                            type="file" 
                            multiple
                            className="form-control"
                            name="fileImage"
                            value={fileImage} 
                            ref={fileInput => fileInputRef = fileInput}
                            onChange={e => loadImage(e)}/>
                        <div>
                            <button className="btn btn-primary" 
                                    onClick={(e) => {e.preventDefault(); fileInputRef.click()}}>
                                <LocalizationHelper text="addProductPickFile"/>
                            </button>
                        </div>
                        {renderImages()}
                </div>
                <div className="form-group mb-4">
                    <div className="row">
                        <div className="col-6">
                            {editedProduct && 
                                <input  
                                        required type="text" className="form-control    input"
                                        placeholder={Localization.getTextLocalization("addProductPlaceholderTitleFR", language)} 
                                        name="titleFR"
                                        value={titleFR} 
                                        onChange={e => onChange(e)}/>
                            }
                        </div>
                        <div className="col-5">
                            {editedProduct && 
                                <input  type="text" className="form-control input"
                                        placeholder={Localization.getTextLocalization("addProductPlaceholderTitleEN", language)} 
                                        name="titleEN"
                                        value={titleEN} 
                                        onChange={e => onChange(e)} />
                            }
                        </div>
                    </div>
                </div>
                <div className="form-group"> 
                
                    {editedProduct && 
                        <EditorWrapper onChange={onChangeDescriptionFR} placeholder={Localization.getTextLocalization("addProductPlaceholderDescriptionFR", language)}
                        value={editedProduct.descriptionFR}
                        />
                    }

     
                </div>
                <div className="form-group"> 

                    {editedProduct && 
                        <EditorWrapper onChange={onChangeDescriptionEN} placeholder={Localization.getTextLocalization("addProductPlaceholderDescriptionEN", language)}
                        value={editedProduct.descriptionEN}/>
                    }
                    
                </div>
                <div className="form-group">
                    <div className="row ml-1">
                        <div className="">
                            <label className="label mr-2">{Localization.getTextLocalization("addProductPlaceholderPriceEuro", language)}</label>
                            <input  className="form-control input"
                                    required type="number" 
                                    placeholder={Localization.getTextLocalization("addProductPlaceholderPriceEuro", language)} 
                                    name="priceEuro"
                                    value={priceEuro} 
                                    onChange={e => onChange(e)} 
                                    maxLength="6"
                                    onFocus={() => {
                                        if (formData.priceEuro === 0) {
                                            setFormData({...formData, priceEuro: ''});
                                        }
                                    }}/>
                        </div>
                        <div className="dollar-field">
                            <label className="label mr-2">{Localization.getTextLocalization("addProductPlaceholderPriceDollar", language)}</label>
                            <input  className="form-control input"
                                    type="number" 
                                    placeholder={Localization.getTextLocalization("addProductPlaceholderPriceDollar", language)} 
                                    name="priceDollar"
                                    value={priceDollar} 
                                    onChange={e => onChange(e)} 
                                    maxLength="6"
                                    onFocus={() => {
                                        if (formData.priceDollar === 0) {
                                            setFormData({...formData, priceDollar: ''});
                                        }
                                    }}  />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    
                    <label className="label mr-2">{Localization.getTextLocalization("addProductPlaceholderActive", language)}</label>
                    <input value={active} onChange={e => {
                            setFormData({...formData, active: !active });
                            }} type="checkbox" name="active" checked={active} />
                    
                    <label className="label mr-2 ml-3">{Localization.getTextLocalization("addProductPlaceholderSold", language)}</label>
                    <input value={sold} onChange={e => {
                            setFormData({...formData, sold: !sold });
                            }} type="checkbox" name="sold" checked={sold} />
                    
                    <label className="label mr-2 ml-3">{Localization.getTextLocalization("addProductPlaceholderNews", language)}</label>
                    <input value={news} onChange={e => {
                            setFormData({...formData, news: !news });
                            }} type="checkbox" name="news" checked={news} />
                            
                    <label className="label mr-2 ml-3">{Localization.getTextLocalization("addProductPlaceholderReserved", language)}</label>
                    <input value={news} onChange={e => {
                            setFormData({...formData, reserved: !reserved });
                            }} type="checkbox" name="reserved" checked={reserved} />
              </div>
            <div className="row mb-2 ml-1">
                {processUpdateInProgress && (
                    <Fragment>
                        <span className="callCloudFunctionMessageOK blink_me">
                            <LocalizationHelper text="productUpdateInProgress"/> 
                        </span>
                        <img className="spinner" src={require('../../../img/spinner.gif')} alt="" />
                    </Fragment>    
                )}

                {!processUpdateInProgress && processUpdateOk && (
                    <Fragment>
                        <span className="callCloudFunctionMessageOK ml-1">
                            <LocalizationHelper text="productUpdateOK"/> 
                        </span>
                    </Fragment>
                )}

                {!processUpdateInProgress && processUpdateFailed && (
                    
                    <Fragment>
                        <span className="callCloudFunctionMessageNOK ml-1">
                            <LocalizationHelper text="productUpdateNOK"/> 
                        </span>
                    </Fragment>
                )}

            </div>
                <button type="submit" disabled={formData.validForm ? '' : 'disabled'} className="btn btn-success my-1 mb-4">
                    <LocalizationHelper text="editProductButton"/>    
                </button>
                <button id="idPreviewButton" onClick={() => {
                    setPositionYpreview($("#idPreviewButton").position().top + 130)
                    setShowModal(true);
                    }} type="button" disabled={formData.validForm ? '' : 'disabled'} className="btn btn-info my-1 mb-4 ml-2">
                    <LocalizationHelper text="addProductPreview"/>    
                </button>
                <div>
                </div>
            </form>
        </div>        
    </Fragment>

    )
}

const mapStateToProps = state => ({
    categories: state.categories.categories,
    language: state.config.language,
    processUpdateInProgress: state.products.processUpdateInProgress,
    processUpdateOk: state.products.processUpdateOk,
    processUpdateFailed: state.products.processUpdateFailed,
    editedProduct: state.products.editedProduct

});

export default connect(mapStateToProps, {loadCategories, updateProduct, fetchProductByID})(EditProduct);
