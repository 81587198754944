import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import '../../css/navbar.css';
import LocalizationHelper from '../../helpers/LocalizationHelper';
import { setLanguage } from '../../redux/actions/configAction';
import ShoppingIcon from './ShoppingIcon';
import firebaseApp from './../../firebase';
import { userAlreadyAuthenticated, logoutUser } from '../../redux/actions/userAction';
import Localization from './../../helpers/Localization';

const Navbar = ({
    language, 
    setLanguage, 
    activeCart, 
    nbrProducts, 
    setShowRegisterCallback,
    setShowLoginCallback,
    isAuthenticated,
    profile,
    userAlreadyAuthenticated,
    logoutUser,
    displayName
}) => {

    const [toggleNavButton, setToggleNavButtontCount] = useState(false);

    if (!language) {
        language = navigator.language;
    }

    let width = window.innerWidth;

    useEffect(() => {

        firebaseApp.auth().onAuthStateChanged(user => {
  
          if (user) {

            let names = ['', ''];
            if (user.displayName) {
                names = user.displayName.split('-');
            }

            userAlreadyAuthenticated({
                email: user.email,
                displayName: user.displayName,
                address: user.photoURL,
                name: names[1],
                firstName: names[0],
                uid: user.uid
            });
          }
    
        });    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = () => {
        firebaseApp.auth().signOut();
        logoutUser();
    }

    return (
        <nav className="navbar navbar-expand-lg fixed-top nav-menu custom-navbar">
            <a href="#home" className="navbar-brand ml-2">
                <span className="navbar-brand-text-first-letter">A</span> 
                <span className="navbar-brand-text">ntique Charme & Déco</span>            
            </a>
            {activeCart &&

                <div style={{display: toggleNavButton || width > 991 ? 'none' : 'block'}} className="mb-4 icon-shop-small-screen">
                    <ShoppingIcon 
                        clickHandler={() => {}}
                        nbrProducts={nbrProducts} />
                </div>                       
            }
            <button onClick={() => setToggleNavButtontCount(!toggleNavButton)}
                    className={toggleNavButton ? "navbar-toggler nav-button change" : "navbar-toggler nav-button"} type="button" 
                    data-toggle="collapse" data-target="#myNavbar">

                <div className="bg-light line1"></div>
                <div className="bg-light line2"></div>
                <div className="bg-light line3"></div>

            </button>
                <div className="collapse navbar-collapse justify-content-end font-weight-bold" 
                     id="myNavbar">
                    <ul className="navbar-nav">

                        <li onClick={() => setToggleNavButtontCount(!toggleNavButton)} className="nav-item clickCollapse">
                            <NavLink to="/home" activeClassName="active" className="nav-link ml-2 mt-1 menu-item">
                                <LocalizationHelper text="home"/>
                            </NavLink>
                        </li>
                        <li onClick={() => setToggleNavButtontCount(!toggleNavButton)} className="nav-item clickCollapse">
                            <NavLink to="/shop" activeClassName="active" className="nav-link ml-2 mt-1 menu-item">
                                <LocalizationHelper text="shop"/>
                            </NavLink>
                        </li>
                        {activeCart &&
                            
                            <ShoppingIcon 
                                clickHandler={() => setToggleNavButtontCount(!toggleNavButton)}
                                nbrProducts={nbrProducts} />
                            
                        }
                        <li onClick={() => setToggleNavButtontCount(!toggleNavButton)} className="nav-item clickCollapse">
                            <NavLink to="/about" activeClassName="active" className="nav-link ml-2 mt-1 menu-item">
                                <LocalizationHelper text="about"/>
                            </NavLink>
                        </li>
                        {/*<li onClick={() => setToggleNavButtontCount(!toggleNavButton)} className="nav-item clickCollapse">
                            <NavLink to="/customers" activeClassName="active" className="nav-link ml-2 mt-1 menu-item">
                                <LocalizationHelper text="customers"/>
                            </NavLink>
                        </li>*/}
                        <li onClick={() => setToggleNavButtontCount(!toggleNavButton)} className="nav-item clickCollapse">
                            <NavLink to="/contact" activeClassName="active" className="nav-link ml-2 mt-1 menu-item">
                                <LocalizationHelper text="contact"/>
                            </NavLink>
                        </li>
                        
                        <li style={{display: activeCart && isAuthenticated ? 'inline' : 'none'}} onClick={() => setToggleNavButtontCount(!toggleNavButton)} className="nav-item clickCollapse">
                            <NavLink to={`/accountHome`} activeClassName="active" className="nav-link ml-2 mt-1 menu-item">
                                <i className="fas fa-user-circle icon-account" title={Localization.getTextLocalization('account', language)}></i>
                            </NavLink>
                        </li>
                        

                        <li style={{display: isAuthenticated ? 'inline' : 'none'}} onClick={() => {
                                    const temp = toggleNavButton;
                                        setToggleNavButtontCount(!temp);
                                        logout();
                                    }} className="nav-item clickCollapse">
                            <NavLink to="/home" activeClassName="active" className="nav-link ml-2 mt-1 menu-item">
                                <i className="fas fa-sign-out-alt icon-logout" title={Localization.getTextLocalization('logout', language)}></i>
                            </NavLink>
                        </li>

                        <li style={{display: activeCart && !isAuthenticated ? 'inline' : 'none'}} onClick={() => {
                                        setToggleNavButtontCount(!toggleNavButton);
                                        setShowLoginCallback();
                                    }} className="nav-item clickCollapse">                               
                            <span className="nav-link ml-2 mt-1 menu-item">
                                <LocalizationHelper text="connection"/>
                            </span>
                        </li>

                        <li style={{display: activeCart && !isAuthenticated ? 'inline' : 'none'}}  onClick={() => {
                                        setToggleNavButtontCount(!toggleNavButton);
                                        setShowRegisterCallback();
                                    }} className="nav-item clickCollapse">
                            <span className="nav-register nav-link border border border-secondary p-1 ml-2 mt-2 menu-item">
                                <LocalizationHelper text="register"/>
                            </span>
                        </li>
                        
                        {profile === 'admin' && 
                            <li style={{display: isAuthenticated ? 'inline' : 'none'}} onClick={() => setToggleNavButtontCount(!toggleNavButton)} className="nav-item clickCollapse">
                                <NavLink to={`/adminjdio`} activeClassName="active" className="nav-link ml-2 mt-1 menu-item">
                                    <LocalizationHelper text="adminTitle"/>
                                </NavLink>
                            </li>                    
                        }


                        { language.indexOf("en") === -1 && 
                            <li className="nav-item">
                                <span className="nav-link ml-2 mt-1 menu-item nav-active">
                                    <img onClick={() => {setLanguage("en");}} className='navbarIcon' src={require('../../img/icons/IconEnglishLanguage.png')} alt="" />
                                </span>
                            </li>
                        }

                        { language.indexOf("fr") === -1  && 
                            <li  className="nav-item">
                                <span className="nav-link ml-2 mt-1 menu-item nav-active">
                                    <img onClick={() => {setLanguage("fr");}} className='navbarIcon' src={require('../../img/icons/IconFrenchLanguage.png')} alt="" />
                                </span>
                            </li>
                        }
                    </ul>
                </div>           
        </nav>
    )
}

const mapStateToProps = state => ({
    language: state.config.language,
    activeCart: state.shoppingCart.activeCart,
    nbrProducts: state.shoppingCart.nbrProducts,
    isAuthenticated: state.user.isAuthenticated,
    profile: state.user.userDatas ? state.user.userDatas.profile : '',
    displayName: state.user.userDatas && state.user.userDatas.displayName ? state.user.userDatas.displayName.replace('-', ' ') : ''
});

export default connect(mapStateToProps, {setLanguage, userAlreadyAuthenticated, logoutUser})(Navbar);
