export const TEXT_LOCALIZATION = {

    and_fr: 'et',
    and_en: 'and',
    delete_fr: 'Supprimer',
    delete_en: 'Delete',
    modify_fr: 'Modifier',
    modify_en: 'Update',
    cancel_fr: 'annuler',
    cancel_en: 'cancel',
    add_fr: 'Ajouter',
    add_en: 'Add',
    yes_fr: "Oui",
    yes_en: "Yes",
    no_fr: "Non",
    no_en: "No",
    sold_fr: "Vendu",
    sold_en: "Sold",
    new_fr: "Nouveau",
    new_en: "New",
    inactive_fr: "Inactif",
    inactive_en: "Inactive",
    reserved_fr: "Réservé",
    reserved_en: "Reserved",

    /** NAVBAR */
    home_fr: "Accueil",
    home_en: "Home",
    shop_fr: "Boutique",
    shop_en: "Shop",
    about_fr: "Qui sommes nous ?",
    about_en: "About US",
    customers_fr: "Clients",
    customers_en: "Customers",
    contact_fr: "Contact",
    contact_en: "Contact",
    connection_fr: "Connexion",
    connection_en: "Connection",
    register_fr: "S'inscrire",
    register_en: "Register",
    logout_fr: "Déconnexion",
    logout_en: "Logout",
    account_fr: 'Accèder à mon compte',
    account_en: 'Access my account',
    login_fr: "Authentifiez-vous",
    login_en: "Authenticate",

    /** HOME */
    homeHeadtitle_fr: "Bienvenue sur notre magasin en ligne",
    homeHeadtitle_en: "Welcome to our online shop",
    homeTitle_fr: "Les objets anciens sont notre passion",
    homeTitle_en: "Ancient objects are our passion",
    homeSubtitle_fr: "Achat, Vente & Expertise : tableaux, mobilier, objets d'art...",
    homeSubtitle_en: "Purchase, Sale & Expertise: paintings, furniture, works of art...",

    /** ABOUT */
    aboutHeaderTitle_1_fr: "Q",
    aboutHeaderTitle_1_en: "S",
    aboutHeaderTitle_2_fr: "uelques mots de présentation",
    aboutHeaderTitle_2_en: "ome word about us",

    /** SHOP */
    shopWelcome_fr: "Bienvenue dans notre boutique",
    shopWelcome_en: "Welcome to our shop",
    shopAddToCart_fr: "Ajouter au panier",
    shopAddToCart_en: "Add to cart",
    shopAll_fr: "Tout",
    shopAll_en: "All",
    shopNew_fr: "Nouveautés",
    shopNew_en: "New",
    shopPainting_fr: "Tableaux",
    shopPainting_en: "Paintings",
    shopJewelry_fr: "Bijoux",
    shopJewelry_en: "Jewelry",
    shopFurniture_fr: "Mobilier",
    shopFurniture_en: "Furniture",
    shopPieceArt_fr: "Objet d'art",
    shopPieceArt_en: "Piece of Art",
    shopVarious_fr: "Divers",
    shopVarious_en: "Various",
    shopSearch_fr: "Chercher",
    shopSearch_en: "Search",
    shopKeywords_fr: "Mot-clés",
    shopKeywords_en: "Keywords",
    shopKeywordsSeparator_fr: "Utiliser le ; comme séparateur",
    shopKeywordsSeparator_en: "Use the ; as separator ;",
    shopSearchReset_fr: "Tout afficher",
    shopSearchReset_en: "Show All",
    shopNoProducts_fr: "Pas de produit dans cette catégorie",
    shopNoProducts_en: "No products in this category",

    /** CONTACT */
    contactCaptcha_fr: "Cliquer sur ' Je ne suis pas un robot ' avant de remplir le formulaire",
    contactCaptcha_en: "Click on ' I am not a robot ' before completing the form",
    contactUs_fr: "Nous contacter",
    contactUs_en: "Contact Us",
    contactMessage_fr: "Nous envoyer un message",
    contactMessage_en: "Send us a message",
    contactSend_fr: "Envoyer",
    contactSend_en: "Send",
    contactYourName_fr: "Votre nom",
    contactYourName_en: "Your name",
    contactYourMail_fr: "Votre email",
    contactYourMail_en: "Your email",
    contactYourMessage_fr: "Votre message",
    contactYourMessage_en: "Your message",
    contactSending_fr: "Envoi en cours ...",
    contactSending_en: "Sending in progress ...",
    contactSendingOK_fr: "Envoi effectué",
    contactSendingOK_en: "Sending done",
    contactSendingNOK_fr: "Erreur lors de l'envoi",
    contactSendingNOK_en: "Sending failed",

    /** ADMIN */
    adminTitle_fr: "Admin",
    adminTitle_en: "Admin",
    adminAddProduct_fr: "Ajouter un produit",
    adminAddProduct_en: "Add product",
    adminEditProduct_fr: "Modifier un produit",
    adminEditProduct_en: "Edit product",
    adminManageProduct_fr: "Gérer les produits",
    adminManageProduct_en: "Manage products",
    adminManageCategories_fr: "Gérer les catégories",
    adminManageCategories_en: "Manage categories",
    adminMessage_fr: "Messages",
    adminMessage_en: "Messages",
    addProductTitle_fr: "Ce formulaire vous permet d'ajouter un produit",
    addProductTitle_en: "This form allows you to add a product",
    addProductEditTitle_fr: "Ce formulaire vous permet de modifier un produit",
    addProductEditTitle_en: "This form allows you to edit a product",
    addProductButton_fr: "Ajouter produit",
    addProductButton_en: "Add product",
    addProductPlaceholderTitleFR_fr: "Titre en français",
    addProductPlaceholderTitleFR_en: "Title in french",
    addProductPlaceholderTitleEN_fr: "Titre en anglais",
    addProductPlaceholderTitleEN_en: "Title in english",
    addProductPlaceholderDescriptionFR_fr: "Description en français",
    addProductPlaceholderDescriptionFR_en: "Description in french",
    addProductPlaceholderDescriptionEN_fr: "Description en anglais",
    addProductPlaceholderDescriptionEN_en: "Description in english",
    addProductPlaceholderPriceEuro_fr: "Prix en euros",
    addProductPlaceholderPriceEuro_en: "Price in euros",
    addProductPlaceholderPriceDollar_fr: "Prix en dollars",
    addProductPlaceholderPriceDollar_en: "Price in dollars",
    addProductPlaceholderActive_fr: "Actif",
    addProductPlaceholderActive_en: "Active",
    addProductPlaceholderSold_fr: "Vendu",
    addProductPlaceholderSold_en: "Sold",
    addProductPlaceholderNews_fr: "Nouveau",
    addProductPlaceholderNews_en: "News",
    addProductPlaceholderReserved_fr: "Réservé",
    addProductPlaceholderReserved_en: "Reserved",
    addProductPickFile_fr: "Sélectionner les photos",
    addProductPickFile_en: "Select photos",
    addProductInProgress_fr: "Ajout en cours ...",
    addProductInProgress_en: "Adding in progress ...",
    addProductPreview_fr: "Prévisualisé",
    addProductPreview_en: "Preview",
    addProductReset_fr: "Réinitialiser fromulaire",
    addProductReset_en: "Reset form",
    addProductOK_fr: "Produit ajouté !!",
    addProductOK_en: "Product added !!",
    addProductFirstImage_fr: "Première image",
    addProductFirstImage_en: "First image",
    addProductNOK_fr: "Echec de l'ajout !!",
    addProductNOK_en: "Failed to add !!",
    fetchProductInProgress_fr: "Chargement des produits en cours ...",
    fetchProductInProgress_en: "Loading products in progress ...",
    fetchCategoriesInProgress_fr: "Chargement des catégories en cours ...",
    fetchCategoriesInProgress_en: "Loading categories in progress ...",
    fetchProductNOK_fr: "Echec du chargement !!",
    fetchProductNOK_en: "Failed to load !!",
    fetchCategoriesNOK_fr: "Echec du chargement !!",
    fetchCategoriesNOK_en: "Failed to load !!",
    manageProductsTitle_fr: "Cette page vous permet de modifier / supprimer vos produits",
    manageProductsTitle_en: "This page allows you to modify / delete your products",
    manageCategoriesTitle_fr: "Cette page vous permet de gérer vos catégories",
    manageCategoriesTitle_en: "This page allows you to manage your categories",
    messagesTitle_fr: "Cette page vous permet de consulter / supprimer vos messages",
    messagesTitle_en: "This page allows you to consult / delete your messages",
    editProductButton_fr: "Modifier produit",
    editProductButton_en: "Update product",

    /** PRODUCT */
    productPublish_fr: "Mis à jour le : ",
    productPublish_en: "Updated on : ",
    productShowDescription_fr: "Afficher la description",
    productShowDescription_en: "Show description",
    productContactUs_fr: "Nous contacter",
    productContactUs_en: "Contact us",
    productEdit_fr: "Modifier",
    productEdit_en: "Edit",
    productDelete_fr: "Supprimer",
    productDelete_en: "Delete",
    productDeleteConfirm_fr: "Confirmer la suppression",
    productDeleteConfirm_en: "Confirm deletion",
    productDeleteConfirmText_fr: "Confirmer la suppression ?",
    productDeleteConfirmText_en: "Confirm the deletion ?",
    productDeleteInProgress_fr : "Suppression en cours",
    productDeleteInProgress_en : "Deletion  in progress",
    productDeleteOK_fr : "Suppression réussie",
    productDeleteOK_en : "Successful deletion",
    productDeleteNOK_fr : "Echec de la suppression",
    productDeleteNOK_en : "Failed to delete",
    productUpdateInProgress_fr : "Modification en cours",
    productUpdateInProgress_en : "Modification  in progress",
    productUpdateOK_fr : "Modification réussie",
    productUpdateOK_en : "Successful modification",
    productUpdateNOK_fr : "Echec de la modification",
    productUpdateNOK_en : "Failed to edit",

    /** CATEGORY */
    categoryExplainDelete_fr: 'La suppression n\'est possible que si la catégorie ne contient pas de produits',
    categoryExplainDelete_en: 'Deletion is only possible if the category does not contain any products', 
    categoryEditTitle_fr: 'Modifier le nom',
    categoryEditTitle_en: 'Update name',
    categoryEditNameFrench_fr: "Nom français",
    categoryEditNameFrench_en: "French name",
    categoryEditNameEnglish_fr: "Nom anglais",
    categoryEditNameEnglish_en: "English name",
    categoryLabelName_fr: 'Nom',
    categoryLabelName_en: 'Name',
    categoryLabelProducts_fr: 'Produits',
    categoryLabelProducts_en: 'Produtcs',
    categoryUpdateInProgress_fr : "Modification en cours",
    categoryUpdateInProgress_en : "Modification  in progress",
    categoryUpdateOK_fr : "Modification réussie",
    categoryUpdateOK_en : "Successful modification",
    categoryUpdateNOK_fr : "Echec de la modification",
    categoryUpdateNOK_en : "Failed to edit",
    categoryDeleteInProgress_fr : "Suppression en cours",
    categoryDeleteInProgress_en : "Deletion  in progress",
    categoryDeleteOK_fr : "Suppression réussie",
    categoryDeleteOK_en : "Successful deletion",
    categoryDeleteNOK_fr : "Echec de la suppression",
    cateogryDeleteNOK_en : "Failed to delete",
    categoryAddText_fr: "Cliquer pour ajouter une catégorie",
    categoryAddText_en: "Click to add category",
    addCategoryInProgress_fr: "Ajout en cours ...",
    addCategoryProgress_en: "Adding in progress ...",
    addCategoryOk_fr: "Ajout effectué",
    addCategoryOk_en: "Add done",
    addCategoryFailed_fr: "Echec de l'ajout",
    addCategoryFailed_en: "Add failed",

    /** SHOPPING CART */
    addToCart_fr: "Ajouter au panier",
    addToCart_en: "Add to cart",
    removeFromCart_fr: "Retier du panier",
    removeFromCart_en: "Remove from cart",
    cartHoverTotal_fr: 'Total : ',
    cartHoverTotal_en: 'Total : ',
    cartWelcomeTitle_fr: 'Liste de vos articles',
    cartWelcomeTitle_en: 'List of your articles',
    cartNoProducts_fr: 'Vous n\'avez actuellement pas de produits dans votre panier',
    cartNoProducts_en: 'You currently have no products in your cart',
    cartNoProductsShop_fr: 'Allez dans notre boutique',
    cartNoProductsShop_en: 'Go to our shop',
    cartBackToShop_fr: 'Boutique',
    cartBackToShop_en: 'Shop',
    cartDeleteProduct_fr: 'Supprimer du panier',
    cartDeleteProduct_en: 'Delete from cart',

    /** REGISTER */
    registerCreateAccount_fr: 'Créer votre compte',
    registerCreateAccount_en: 'Create your account',
    registerEmail_fr: 'Adresse email',
    registerEmail_en: 'E-mail adress',
    registerName_fr: 'Nom',
    registerName_en: 'Name',
    registerFirstName_fr: 'Prénom',
    registerFirstName_en: 'First Name',
    registerAddress_fr: 'Adresse',
    registerAddress_en: 'Address',
    registerPassword_fr: 'Mot de passe',
    registerPassword_en: 'Password',
    registerConfirmPassword_fr: 'Confirmer mot de passe',
    registerConfirmPassword_en: 'Confirm password',
    registerPhoneNumber_fr: 'Téléphone',
    registerPhoneNumber_en: 'Phone Number',
    registerAllFields_fr: 'Champs obligatoires',
    registerAllFields_en: 'Required fields',
    registerPassworError_fr: 'Les mots de passe ne correspondent pas',
    registerPassworError_en: 'Passwords do not match',
    registerInProgress_fr: 'Création de votre compte',
    registerInProgress_en: 'Create your account',
    registerOk_fr: 'Compte créé avec succés',
    registerOk_en: 'Account created successfully',
    registerFailed_fr: 'Echec à la création du compte',
    registerFailed_en: 'Failed to create account',
    registerFailedEmail_fr: 'Email déjà utilisé par un autre compte',
    registerFailedEmail_en: 'The email address is already in use by another account',
    loginInProgress_fr: 'Authentification en cours',
    loginInProgress_en: 'Authentication in progress',
    loginOk_fr: 'Authentification réussi',
    loginOk_en: 'Successful authentication',
    loginFailed_fr: 'Echec de votre authentification',
    loginFailed_en: 'Failed to authenticate',
    loginFailedWrong_fr: 'Email ou mot de passe erronés',
    loginFailedWrong_en: 'Wrong email or password',
    loginForgottenPassword1_fr: 'Mot de passe oublié : ',
    loginForgottenPassword1_en: 'Forgot your password : ',
    loginForgottenPassword2_fr: 'cliquez ici pour un nouveau',
    loginForgottenPassword2_en: 'click here for a new',
    loginSetEmail_fr: 'Veuillez saisir votre email et cliquez de nouveau',
    loginSetEmail_en: 'Please enter your email and click again',
    loginSendEmail_fr: 'Un mail vient de vous être envoyé',
    loginSendEmail_en: 'An email has just been sent to you',
    loginSendEmailNotExists_fr: 'Email introuvable',
    loginSendEmailNotExists_en: 'Email not found',
    loginSendEmailError_fr: 'Une erreur est survenue, merci de faire un nouvel essai',
    loginSendEmailError_en: 'An error has occurred, please try again',

    /** ACCOUNT */
    accountWelcome_fr: 'bienvenue dans votre espace personnel',
    accountWelcome_en: 'welcome to your personal space',
    accountTabInfo_fr: 'Informations personnelles',
    accountTabInfo_en: 'Personal informations',
    accountTabOrder_fr: 'Vos commandes',
    accountTabOrder_en: 'Your orders',
    acountUserUpdateInProgress_fr: 'Mise à jour en cours',
    acountUserUpdateInProgress_en: 'Update in progress',
    acountUserUpdateOk_fr: 'Mise à jour effectuée',
    acountUserUpdateOk_en: 'Update completed',
    acountUserUpdateFailed_fr: 'Erreur lors de la mise à jour',
    acountUserUpdateFailed_en: 'Error updating',
    acountUserUpdateReconnect_fr: 'Vous devez vous déconnecter/reconnecter pour pouvoir modifier votre compte',
    acountUserUpdateReconnect_en: 'You must log out / log in to edit your account',

    /** PERSONNAL INFOS */
    personnalInfosTitle_fr: 'Ici, vous pouvez modifier vos informations',
    personnalInfosTitle_en: 'Here you can edit your information',
    personalUpdate_fr: 'Enregistrer modifications',
    personalUpdate_en: 'Save changes',

    /** MESSAGE ERREURS */
    categoryAlreadyExist_fr: 'La catégorie existe déjà',
    categoryAlreadyExist_en: 'Category already exists',
}