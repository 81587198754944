import React from 'react';
import { connect } from 'react-redux';
import '../css/home.css';
import LocalizationHelper from '../helpers/LocalizationHelper';

const Home = () => {

    return (

    <header className="home-background">
        <div className="text-md-left text-center names">
            <p className="">Antique Charme & Déco</p>
        </div>
        <div className="text-light text-md-right text-center banner">
            <h3 className="banner-headTitle"><LocalizationHelper text="homeHeadtitle"/></h3>
            <span><h1 className="banner-title"><LocalizationHelper text="homeTitle"/></h1> <div className="os-animation fadeInDownBig p-3 heart-beat"><img src={require('../img/HeartBeat.gif')} alt="" width="32px"/></div> </span>
            <p className="banner-subTitle"><LocalizationHelper text="homeSubtitle"/></p>
        </div>
    </header>
    )
}

export default connect(null, {})(Home);
