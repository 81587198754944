import React, {useState, Fragment} from 'react';
import { connect } from 'react-redux';
import { fetchProductByID } from '../../redux/actions/productAction';
import '../../css/admin.css';
import LocalizationHelper from './../../helpers/LocalizationHelper';
import AddProduct from './Product/AddProduct';
import EditProduct from './Product/EditProduct';
import ManageProducts from './Product/ManageProducts';
import Messages from './Message/Messages';
import ManageCategories from './Category/ManageCategories';

const Admin = ({match: {params: {id} }, isAuthenticated}) => {

    const [addProductActive, setAddProductActive] = useState(id ? false : true);
    const [editProductActive, setEditProductActive] = useState(id ? true : false);
    const [manageProductsActive, setManageProductsActive] = useState(false);
    const [manageCategoriesActive, setManageCategoriesActive] = useState(false);
    const [messagesActive, setMessagesActive] = useState(false);

    const classAddProduct = addProductActive ? 'nav-link active-link' : 'nav-link custom-link';
    const classEditProduct = editProductActive ? 'nav-link active-link tab-item-mt' : 'nav-link custom-link tab-item-mt';
    const classManageProducts = manageProductsActive ? 'nav-link tab-item-mt active-link' : 'nav-link tab-item-mt custom-link';
    const classManageCategories = manageCategoriesActive ? 'nav-link tab-item-mt active-link' : 'nav-link tab-item-mt custom-link';
    const classMessages = messagesActive ? 'nav-link tab-item-mt active-link' : 'nav-link tab-item-mt custom-link';

    return (

        <Fragment>

            {isAuthenticated &&
                <div className="admin">

                    <ul className="nav nav-tabs">

                        <li className="nav-item">
                            <span onClick={() => {
                                    setAddProductActive(true);
                                    setEditProductActive(false);
                                    setManageProductsActive(false);
                                    setManageCategoriesActive(false);
                                    setMessagesActive(false);
                                    }}  
                                    className={classAddProduct} 
                                    style={{border: "none"}}>
                                    <span className="tab-title">
                                        <LocalizationHelper text="adminAddProduct" />
                                    </span>
                            </span>
                        </li>
                        {id && 
                            <li className="nav-item ml-1">
                                <span onClick={() => {
                                        setAddProductActive(false);
                                        setEditProductActive(true);
                                        setManageProductsActive(false);
                                        setManageCategoriesActive(false);
                                        setMessagesActive(false);
                                        }}  
                                        className={classEditProduct} 
                                        style={{border: "none"}}>
                                        <span className="tab-title">
                                            <LocalizationHelper text="adminEditProduct" />
                                        </span>
                                </span>
                            </li>
                        }

                        <li className="nav-item ml-1">
                            <span onClick={() => {
                                    setAddProductActive(false);
                                    setEditProductActive(false);
                                    setManageProductsActive(true);
                                    setManageCategoriesActive(false);
                                    setMessagesActive(false);
                                    }} 
                                className={classManageProducts} 
                                style={{border: "none"}}>
                                <span className="tab-title">
                                    <LocalizationHelper text="adminManageProduct"/>
                                </span>
                            </span>
                        </li>

                        <li className="nav-item ml-1">
                            <span onClick={() => {
                                    setAddProductActive(false);
                                    setEditProductActive(false);
                                    setManageProductsActive(false);
                                    setManageCategoriesActive(true);
                                    setMessagesActive(false);
                                    }} 
                                className={classManageCategories} 
                                style={{border: "none"}}>
                                <span className="tab-title">
                                    <LocalizationHelper text="adminManageCategories"/>
                                </span>
                            </span>
                        </li>

                        <li className="nav-item ml-1">
                            <span onClick={() => {
                                    setAddProductActive(false);
                                    setEditProductActive(false);
                                    setManageProductsActive(false);
                                    setManageCategoriesActive(false);
                                    setMessagesActive(true);
                                    }}  
                                    className={classMessages} 
                                    style={{border: "none"}}>
                                <span className="tab-title">
                                    <LocalizationHelper text="adminMessage"/>
                                </span>
                            </span>
                        </li>
                    </ul>

                    {addProductActive && <AddProduct/>}
                    {editProductActive && <EditProduct productId={id}/>}
                    {manageProductsActive && <ManageProducts/>}
                    {manageCategoriesActive && <ManageCategories/>}
                    {messagesActive && <Messages/>}

                </div>
            }

            {! isAuthenticated &&
            
            <div className="admin text-muted" >
                Vous devez être authentifié pour afficher cette page
            </div>
            }
        </Fragment>
        

    )
}

const mapStateToProps = state => ({
    language: state.config.language,
    searchProductsInProgress: state.products.searchProductsInProgress,
    editedProduct: state.products.editedProduct,
    isAuthenticated: state.user.isAuthenticated
});

export default connect(mapStateToProps, {fetchProductByID})(Admin);