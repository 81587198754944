import { LOAD_CATEGORIES_IN_PROGRESS, LOAD_CATEGORIES, LOAD_CATEGORIES_FAIL, 
         UPDATE_CATEGORY_IN_PROGRESS, UPDATE_CATEGORY_OK, UPDATE_CATEGORY_FAIL,
         DELETE_CATEGORY_IN_PROGRESS, DELETE_CATEGORY_OK, DELETE_CATEGORY_FAIL,
         DELETE_CATEGORY_RESET, ADD_CATEGORY_IN_PROGRESS, ADD_CATEGORY_OK, 
         ADD_CATEGORY_FAIL, SEARCH_PRODUCTS
       } from '../actions/types';
import UtilsHelper from '../../helpers/UtilsHelper'

const initialState = {
    categories: [],
    loadingInProgress: false,
    loadingOk: false,
    loadingFailed: false,
    addInProgress: false,
    addOk: false,
    addFailed: false,
    updateInProgress: false,
    updateOk: false,
    updateFailed: false,
    deleteInProgress: false,
    deleteOk: false,
    deleteFailed: false,
    idCategoryUpdated: null,
    idCategoryDeleted: null,
    resetSearch: false,
    errorMessage: ''
}

export default function(state = initialState, action) {

    const {type, payload} = action;

    switch(type) {

        case LOAD_CATEGORIES_IN_PROGRESS:
            return {...state, loadingInProgress: true};

        case LOAD_CATEGORIES:

            // tri
           const categories = UtilsHelper.sortCategories(payload.categories, payload.language);

            return {...state, 
                    categories: categories,
                    loadingInProgress: false, loadingOk: true, loadingFailed: false};

        case LOAD_CATEGORIES_FAIL:
            return {...state, loadingInProgress: false, loadingOk: false, loadingFailed: true};

        case ADD_CATEGORY_IN_PROGRESS: 
            return {...state, addInProgress: true, addOk: false, addFailed: false};

        case ADD_CATEGORY_OK: 

            const categoryAdded = payload.category;
            let allCategories = [...state.categories, categoryAdded];
            const categoriesSort = UtilsHelper.sortCategories(allCategories, payload.language);

            return {...state, categories: categoriesSort, addInProgress: false, addOk: true, addFailed: false};

        case ADD_CATEGORY_FAIL: 
            return {...state, errorMessage: payload, addInProgress: false, addOk: false, addFailed: true};

        case UPDATE_CATEGORY_IN_PROGRESS:
            return {...state, idCategoryUpdated: payload, updateInProgress: true, updateOk: false, updateFailed: false};

        case UPDATE_CATEGORY_OK:

            let categoriesUpdate = [];
            if (state.categories) {

                categoriesUpdate = state.categories.map(category => {

                    if (category._id !== payload._id) {
                        return category;
                    } else {
                        return payload;
                    }
                });
            }

            return {...state, categories: categoriesUpdate, updateInProgress: false, updateOk: true, updateFailed: false};

        case UPDATE_CATEGORY_FAIL:
            return {...state, updateInProgress: false, updateOk: false, updateFailed: true};

        case DELETE_CATEGORY_IN_PROGRESS: {
            return {...state, idCategoryDeleted: payload, deleteInProgress: true, deleteOk: false, deleteFailed: false};
        }

        case DELETE_CATEGORY_OK:

            let categoriesDelete = [];
            if (state.categories.length > 0) {

                state.categories.forEach(category => {
                    if (category._id !== payload) {
                        categoriesDelete.push(category);
                    }                    
                })
            }

            return {...state, categories: categoriesDelete, deleteInProgress: false, deleteOk: true, deleteFailed: false};

        case DELETE_CATEGORY_FAIL:
            return {...state, deleteInProgress: false, deleteOk: false, deleteFailed: true};

        case DELETE_CATEGORY_RESET:
            return {...state, deleteOk: false};

        case SEARCH_PRODUCTS:

            let resetSearch = false;
            if (!payload.resultOfSearch) {
                resetSearch = true;;
            }
            return {...state, resetSearch: resetSearch};

        default:
            return state;
    }
}