import firebase from "firebase";
import 'firebase/auth';

console.log(process.env);

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAZYChL8_eMBLiuyGzmfhJzwCy5nLkVbXk",
    authDomain: "jacquesmarieshop.firebaseapp.com",
    databaseURL: "https://jacquesmarieshop.firebaseio.com",
    projectId: "jacquesmarieshop",
    storageBucket: "jacquesmarieshop.appspot.com",
    messagingSenderId: "580525782379",
    appId: "1:580525782379:web:71a65892678d98bf"
});

/*const db = firebaseApp.firestore();

export { db };*/

export default firebaseApp;
