import React, {useState} from 'react';
import { connect } from 'react-redux';
import LocalizationHelper from '../../../helpers/LocalizationHelper';
import { addCategory } from '../../../redux/actions/categoryAction'; 
import '../../../css/add-category.css';

const AddCategory = ({
    language, 
    addInProgress, 
    addFailed,
    addOk,
    openAddCategory, 
    addCategory,
    errorMessage
}) => {

    const [formData, setFormData] = useState({
        nameFR: '',
        nameEN: ''
    });

    /**
     * 
     * Création de la categorie
     * 
     * @param {*} e 
     */
    const onSubmit = e => {

        e.preventDefault();

        console.log("addCategory formData = ", formData);

        addCategory(formData, language);
    }

    return (
        <form id="idFormAddCategory" className="form display-none" onSubmit={e => onSubmit(e)}>
            <div className="column mt-3 input-add-category border shadow">
                <div className="mb-3">
                    <label className="category-edit-label ml-2 mt-2">
                        <LocalizationHelper text="categoryEditNameFrench"/>
                    </label>
                    <input  
                        required type="text" className="form-control category-edit-input"
                        name="nameFR"
                        value={formData.nameFR} 
                        onChange={e => setFormData({...formData, nameFR: e.target.value})} 
                        maxLength="30"/>
                </div>
                <div className="mb-3">
                    <label className="category-edit-label ml-2 mt-2">
                        <LocalizationHelper text="categoryEditNameEnglish"/>
                    </label>
                    <input  
                        required type="text" className="form-control category-edit-input"
                        name="nameEN"
                        value={formData.nameEN} 
                        onChange={e => setFormData({...formData, nameEN: e.target.value})} 
                        maxLength="30"/>
                </div>
                <div className="row justify-content-center">
                    <button type="submit" className="btn btn-success mt-3 mb-4">
                        <LocalizationHelper text="add"/>    
                    </button>
                    <button onClick={openAddCategory} type="button" className="btn btn-danger mt-3 mb-4 ml-3">
                        <LocalizationHelper text="cancel"/>    
                    </button>
                </div>
                {addInProgress && (
                    <div className="row justify-content-center mb-2">
                        <span className="callCloudFunctionMessageOK blink_me">
                            <LocalizationHelper text="addCategoryInProgress"/> 
                        </span>
                        <img className="spinner" src={require('../../../img/spinner.gif')} alt="" />
                    </div>  
                )}

                {!addInProgress && addOk && ( 
                    <div className="row justify-content-center mb-2">
                        <span className="callCloudFunctionMessageOK ml-1">
                            <LocalizationHelper text="addCategoryOk"/> 
                        </span>
                    </div>
                )}

                {!addInProgress && addFailed && (
                    <div className="row justify-content-center mb-2">
                        <span className="callCloudFunctionMessageNOK ml-1">
                            {errorMessage}
                        </span>
                    </div>  
                )}
            </div> 
        </form>
    )

}

const mapStateToProps = state => {
    return { 
        addInProgress: state.categories.addInProgress,
        addFailed: state.categories.addFailed,
        addOk: state.categories.addOk,
        errorMessage: state.categories.errorMessage
    }
}

export default connect(mapStateToProps, {addCategory})(AddCategory);