import React, {useState, Fragment} from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import LocalizationHelper from '../../../helpers/LocalizationHelper';
import Localization from '../../../helpers/Localization';
import { deleteProduct, resetFlagDelete, updateProductCheckbox} from '../../../redux/actions/productAction';
import '../../../css/product-item.css'

let width = window.innerWidth;  

function ProductItemManageFooter({
    language,
    product,
    deleteInProgress,
    productIdDeleted,
    history,
    deleteProduct,
    resetFlagDelete,
    updateProductCheckbox,
    setActiveProduct,
    setSoldProduct,
    setNewsProduct,
    setReservedProduct
}) {

    const [showDeleteBlock, setShowDeleteBlock] = useState(false);
    const [active, setActive] = useState(product.active);
    const [sold, setSold] = useState(product.sold);
    const [news, setNews] = useState(product.news);
    const [reserved, setReserved] = useState(product.reserved);

    /**
     * 
     * Gère action suivant type de bouton cliqué
     * 
     * @param {*} buttonType 
     * @param {*} productId
     */
    const manageProduct = (buttonType, productId) => {

        if (buttonType === "danger") {
          setShowDeleteBlock(true);
          resetFlagDelete();
        } else if (buttonType === "success") {
          redirectToEdit(productId);
        }
      }
  
      /**
       * 
       * Redirection vers la page d'édition
       * 
       * @param {*} productId 
       */
      const redirectToEdit = productId => {
        const editUrl = "/admin/product/" + productId;
        history.push(editUrl);
        window.location.reload();
      }
  
      /**
       * 
       * Permet le rendu des boutons "modifier"/"supprimer" en mode manage
       * Passe aussi le productId pour l'action à faire
       * 
       * @param {*} buttonType 
       * @param {*} buttonText 
       * @param {*} productId 
       */
      const renderManageButtons = (buttonType, buttonText, productId) => {
  
        let className = "";
  
        if (width < 450) {
          className=`btn btn-outline-${buttonType} btn-sm mb-2`
        } else {
          className=`btn btn-outline-${buttonType} mb-4`
        }
  
        return (
            <button onClick={e => manageProduct(buttonType, productId)}  className={className} >
                <LocalizationHelper text={buttonText}/>
            </button>
        )
      }

    /**
     * 
     * Modification état checkbox 
     * 
     * @param {*} _id 
     * @param {*} checkboxType 
     * @param {*} value 
     */
    const updateCheckboxState = (_id, checkboxType, value) => {

        const productCheckbox = {
          _id,
          checkboxType,
          value
        }
  
        updateProductCheckbox(productCheckbox);
  
        switch (checkboxType) {
          case "active":
              setActive(!active);
              setActiveProduct(!active);
              break;
          case "sold":
              setSold(!sold);
              setSoldProduct(!sold);
              break;
          case "news":
              setNews(!news);
              setNewsProduct(!news);
              break;
          case "reserved":
              setReserved(!reserved);
              setReservedProduct(!reserved);
              break;
          default:
              break;
        }
    }

    
    return (
        <Fragment>

            {!showDeleteBlock &&
                <div className="manage-checkbox d-flex flex-row flex-wrap">
                    <label className="label-checkbox-active mr-2">{Localization.getTextLocalization("addProductPlaceholderActive", language)}</label>
                    <input value={active} onChange={e => {
                        updateCheckboxState(product._id, "active", !active);
                    }} type="checkbox" name="active" checked={active} />
                    
                    <label className="label-checkbox-sold mr-2 ml-2">{Localization.getTextLocalization("addProductPlaceholderSold", language)}</label>
                    <input value={sold} onChange={e => {
                        updateCheckboxState(product._id, "sold", !sold);
                    }} type="checkbox" name="sold" checked={sold} />

                    <label className="label-checkbox-new mr-2 ml-2">{Localization.getTextLocalization("addProductPlaceholderNews", language)}</label>
                    <input value={news} onChange={e => {
                        updateCheckboxState(product._id, "news", !news);
                    }} type="checkbox" name="news" checked={news} />

                    <label className="label-checkbox-new mr-2 ml-2">{Localization.getTextLocalization("addProductPlaceholderReserved", language)}</label>
                    <input value={reserved} onChange={e => {
                        updateCheckboxState(product._id, "reserved", !reserved);
                    }} type="checkbox" name="reserved" checked={reserved} />
                </div>   
            }                 

            <div className="manage-button row mb-2" style={{position: "absolute", bottom: "0rem"}}>

       
                {!showDeleteBlock && !deleteInProgress && 
                    renderManageButtons("success", "productEdit", product._id)
                }
                {!showDeleteBlock && !deleteInProgress &&
                    renderManageButtons("danger", "productDelete")                       
                } 

                {showDeleteBlock &&
                    <div className="delete-confirm-text ml-2">
                    <h6 className="font-weight-bold">
                        <LocalizationHelper text="productDeleteConfirmText"/>
                    </h6>
                    <div className="delete-confirm-buttons mt-3">
                        <button type="button" onClick={e => {setShowDeleteBlock(false); deleteProduct(product._id)}}  className="btn btn-danger btn-sm  mt-1" >
                        <LocalizationHelper text="yes"/>
                        </button>
                        <button type="button" onClick={e => {setShowDeleteBlock(false)}}  className="btn btn-primary btn-sm mt-1" >
                        <LocalizationHelper text="no"/>
                        </button>
                    </div>
                    </div>              
                }


                {deleteInProgress && product._id === productIdDeleted && (
                    <Fragment>
                        <span className="callCloudFunctionMessageOK blink_me mb-2">
                            <LocalizationHelper text="productDeleteInProgress"/> 
                        </span>
                        <img className="spinner" src={require('../../../img/spinner.gif')} alt="" />
                    </Fragment>    
                )}

            </div>
      </Fragment>
    )
}

const mapStateToProps = state => ({
    deleteInProgress: state.products.deleteInProgress,
    productIdDeleted: state.products.productIdDeleted,
    language: state.config.language
  });

export default compose( 
    withRouter, 
    connect(mapStateToProps, 
      {deleteProduct, 
       resetFlagDelete, 
       updateProductCheckbox,}) )(ProductItemManageFooter);