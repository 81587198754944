import React, {Fragment} from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import '../../css/cart-products.css';
import LocalizationHelper from '../../helpers/LocalizationHelper';
import UtilsHelper from '../../helpers/UtilsHelper';
import { removeFromCart } from '../../redux/actions/shoppingCartAction';
import Localization from '../../helpers/Localization';

function CartDetailProducts({ 
    products, 
    language, 
    removeFromCart 
}) {

    const renderProducts = () => {

        let totalPrice = 0;
        let width = window.innerWidth;
        const priceCurrency = language === 'fr' ? ' €' : ' $';

        return products.map((product, index) => {

            const title = language === 'fr' ? product.titleFR : product.titleEN;
            let description = language === 'fr' ? product.descriptionFR : product.descriptionEN;
            const price = language === 'fr' ? product.priceEuro  : product.priceDollar;
            totalPrice += language === 'fr' ? Number.parseInt(product.priceEuro) : Number.parseInt(product.priceDollar);

            if (width < 550 && description.length > 100) {
                description = description.substring(0, 47);
                description = description + '...';
            }

            return (
                <Fragment key={product._id} >
                    <div className="d-flex flex-row align-items-center os-animation fadeInUpSpeed">
                        <div className="col-lg-2 col-md-3 col-sm-4 col-4 cart-img">
                            <img className="cart-products-image" src={product.imageUrl[product.firstImageIndex]} alt=""></img>
                        </div>
                        <div className="ml-lg-4 col-lg-10 col-md-9 col-sm-8 col-8 d-flex flex-column justify-content-center">
                            <div className="cart-body">
                                <p className="cart-products-title">{title}</p>
                                <p className="cart-products-desciption"><span dangerouslySetInnerHTML={{ __html: description }} /></p>
                            </div>
                            <div className="cart-products-price-container d-flex align-items-end mr-3">
                                <span className="py-3">
                                    <button className="delete-product-cart-button" onClick={() => {removeFromCart(product)}} >
                                        <i className="fas fa-trash-alt text-danger ml-2 mr-2 icon" title={Localization.getTextLocalization('cartDeleteProduct', language)}></i>
                                    </button>
                                </span>
                                <p className="cart-products-price"><NumberFormat value={price} displayType={'text'} format={UtilsHelper.getNumberMaskFormat(price)} /> {priceCurrency}</p>
                            </div>
                        </div>
                    </div>
                    <div className="cart-hover-separator"></div>
                    {index === products.length - 1 && (
                    <div className="row align-items-center os-animation fadeInUpSpeed">
                        <div className="col-5 justify-content-center">
                            <NavLink to="/shop" activeClassName="active" className="nav-back">
                                <span className="cart-products-back">
                                    <i className="fas fa-long-arrow-alt-left mr-2 ml-3"></i>
                                    <LocalizationHelper text="cartBackToShop" />
                                </span>
                            </NavLink>
                        </div>

                        <div className="d-flex flex-row col-7 justify-content-end">
                            <div>
                                <p className="cart-products-total"><LocalizationHelper text="cartHoverTotal" /></p>
                            </div>
                            <div>
                                <p className="cart-products-total-price"><NumberFormat value={totalPrice} displayType={'text'} format={UtilsHelper.getNumberMaskFormat(totalPrice)} /> {priceCurrency}</p>
                            </div>
                        </div>
                    </div>
                    )}
                </Fragment>
            )
        })
    }

    return (
        <div className="cart-products">
            {renderProducts()}
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.shoppingCart.products,
    language: state.config.language,
});

export default connect(mapStateToProps, {removeFromCart})(CartDetailProducts);
