import { TEXT_LOCALIZATION } from '../configuration/TextLocalization';
import { connect } from 'react-redux';

const LocalizationHelper = (props) => {

    const {text, language} = props;

    // localise le texte suvant la locale
    function getTextLocalization() {

        const localisationText = text + '_' + language;
        let localisation = TEXT_LOCALIZATION[localisationText];
        if (localisation == null || localisation === undefined) {
            localisation = TEXT_LOCALIZATION[text + '_en'];
        }

        return localisation;
    } 
    
    return getTextLocalization();

}

const mapStateToProps = state => {
    return {
      language: state.config.language
    }
}

export default connect(mapStateToProps, {})(LocalizationHelper);