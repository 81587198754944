import React, {Component} from 'react';
import RichTextEditor from 'react-rte';

export default class EditorWrapper extends Component {

    state = {
        value: RichTextEditor.createEmptyValue()
      }

    componentDidMount() {
        const valueInit = this.props.value ? this.props.value : "";
        this.setState({value: RichTextEditor.createValueFromString(valueInit, 'html')});
    }

    onChange = (value) => {
        this.setState({value});

        if (this.props.onChange) {
            this.props.onChange(
              value.toString('html')
            );
          }
    }

    componentWillReceiveProps(nextProps) {
    }

    render() {

        return (
            <RichTextEditor
                value={this.state.value}
                onChange={this.onChange}
                placeholder={this.props.placeholder}
            />
        )
    }

}