import { ADD_PRODUCT_IN_PROGRESS, ADD_PRODUCT_OK, ADD_PRODUCT_FAIL, FETCH_PRODUCT_BY_ID,
         FETCH_PRODUCT_IN_PROGRESS, FETCH_PRODUCT_OK, FETCH_PRODUCT_FAIL, SEARCH_PRODUCTS, 
         SEARCH_PRODUCTS_IN_PROGRESS, DELETE_PRODUCT_IN_PROGRESS, DELETE_PRODUCT_OK,
         DELETE_PRODUCT_FAIL, DELETE_PRODUCT_RESET, UPDATE_PRODUCT_IN_PROGRESS,
        UPDATE_PRODUCT_OK, UPDATE_PRODUCT_FAIL, UPDATE_PRODUCT_CHECKBOX,
        FETCH_PAGINATION_PRODUCTS } from '../actions/types';

const initialState = {
    processAddInProgress: false,
    processAddOk: false,
    processAddFailed: false,
    processUpdateInProgress: false,
    processUpdateOk: false,
    processUpdateFailed: false,
    processInProgress: false,
    processOk: false,
    processFailed: false,
    searchProductsInProgress: false,
    deleteInProgress: false,
    deleteOk: false,
    deleteFailed: false,
    products: [],
    productsOriginal: [],
    productIdDeleted: null,
    editedProduct: null,
    imageUrlToDelete: [],
    lastUpdateProductsList: Date.now(),
    resultOfSearch: false
}

let fetchPorductsStart = 0;

export default function(state=initialState, action) {

    const {type, payload} = action;

    switch(type) {

        case ADD_PRODUCT_IN_PROGRESS:
            return {...state, processAddInProgress: true, processAddOk: false, processAddFailed: false};

        case ADD_PRODUCT_OK:
                return {...state, processAddInProgress: false, processAddOk: true, processAddFailed: false};
    
        case ADD_PRODUCT_FAIL:
            return {...state, processAddInProgress: false, processAddOk: false, processAddFailed: true};
                  
        case UPDATE_PRODUCT_IN_PROGRESS:
            return {...state, processUpdateInProgress: true, processUpdateOk: false, processUpdateFailed: false};
    
        case UPDATE_PRODUCT_OK:
            return {...state, editedProduct: payload, processUpdateInProgress: false, processUpdateOk: true, processAddFailed: false};
        
        case UPDATE_PRODUCT_FAIL:
            return {...state, processUpdateInProgress: false, processUpdateOk: false, processUpdateFailed: true};
        
        case UPDATE_PRODUCT_CHECKBOX:

            const {_id, checkboxType, value} = payload;
            const productsOriModif = state.productsOriginal.map(product => {
                if (product._id === _id) {
                    product[checkboxType] = value;
                    return product;
                } else {
                    return product;
                }
            });

            return {...state, products: productsOriModif, productsOriginal: productsOriModif}

        case FETCH_PRODUCT_IN_PROGRESS: 
            fetchPorductsStart =  Date.now();
            console.log("Fecth products Start = ", fetchPorductsStart);
            return {...state, processInProgress: true};
        
        case FETCH_PRODUCT_OK: 
            console.log("Fecth products Stop = ", Date.now() - fetchPorductsStart);
            return {...state, 
                    products: payload, 
                    productsOriginal: payload, 
                    processInProgress: false, 
                    processOk: true, 
                    processFailed: false,
                    lastUpdateProductsList: Date.now(),
                    resultOfSearch: false};
        
        case FETCH_PRODUCT_BY_ID: 
            return {...state, editedProduct: payload, searchProductsInProgress: false, processOk: false, processFailed: false};

        case FETCH_PRODUCT_FAIL:
            return {...state, processInProgress: false, processOk: false, processFailed: true};

        case DELETE_PRODUCT_IN_PROGRESS: 
            return {...state, productIdDeleted: payload, deleteInProgress: true};

        case DELETE_PRODUCT_OK: 

            const productsNotDeleted = state.products.filter(product => product._id !== payload)
            return {...state, products: productsNotDeleted, deleteInProgress: false, deleteOk: true, deleteFailed: false};

        case DELETE_PRODUCT_FAIL:
            return {...state, deleteInProgress: false, deleteOk: false, deleteFailed: true};

        case DELETE_PRODUCT_RESET: 
            return {...state, deleteInProgress: false, deleteOk: false, deleteFailed: false};

        case SEARCH_PRODUCTS_IN_PROGRESS: 
            return {...state, searchProductsInProgress: true}; 

        case SEARCH_PRODUCTS:
            let productsTemp = state.productsOriginal;
            if (payload.resultOfSearch) {
                productsTemp = payload.products;
            }
            console.log("SEARCH_PRODUCTS reducer = ", productsTemp);
            return {...state, products: productsTemp, searchProductsInProgress: false, resultOfSearch: payload.resultOfSearch} 

        case FETCH_PAGINATION_PRODUCTS:
            const finalProducts = [...state.products, ...state.products];
            return {...state, products: finalProducts};

        default:
            return state;
    }
}