import React, {useState, Fragment} from 'react';
import Modal from "react-animated-modal";
import '../../css/login.css';
import { connect } from 'react-redux';
import LocalizationHelper from '../../helpers/LocalizationHelper';
import { loginUser, resetLoginUser } from './../../redux/actions/userAction';
import $ from 'jquery';
import firebaseApp from './../../firebase';
import Localization from './../../helpers/Localization';

function Login({
    visible, 
    language,
    closeModalCallback,
    loginUser,
    loginInProgress,
    loginOk,
    loginFailed,
    message,
    resetLoginUser
}) {


    const [formData, setFormData] = useState({
        email: '',
        password: '',
        message: ''
    });

    const [resetPasswordForm, setResetPasswordForm] = useState({
        isValid: true,
        message: ''
    });

    const [sendPassword, setSendPassword] = useState({
        resetSend: false,
        message: ''
    });

    /**
     * 
     * Modification formulaire suivant entrée dans les input
     * 
     * @param {*} e 
     */
    const onChange = e => {
        
        setFormData({...formData, [e.target.name]: e.target.value});

        if (sendPassword.resetSend === true && e.target.name === 'password' && e.target.value.trim() !== '') {
            setSendPassword({
                resetSend: false,
                message: ''
            });
        }

        if (resetPasswordForm.isValid === false && e.target.name === 'email' && e.target.value.trim() !== '') {
            setResetPasswordForm({
                isValid: true,
                message: ''
            });
        }
    }


    /**
     * 
     * Fadeout sur modal et reset du login ok
     * 
     */
    const fadeOutModal = () => {

        setTimeout(() => {

            $('#loginModal').addClass('loginFadeOut');
            
            setTimeout(() => {
                resetLoginUser();
                closeModalCallback();
            }, 1800);
            
        }, 1000)
          
    }

    /**
     * 
     * Reset mot de passe
     * 
     * @param {*} e 
     */
    const resetPassword = () => {

        if (!formData.email || formData.email.trim() === '') {
            setResetPasswordForm({
                isValid: false,
                message: Localization.getTextLocalization('loginSetEmail', language)
            });
        }

        
        firebaseApp.auth().sendPasswordResetEmail(formData.email)
                   .then(() => {
                        setSendPassword({
                            resetSend: true,
                            message: Localization.getTextLocalization('loginSendEmail', language)
                        })
                   })
                   .catch( err => {

                       let message = ''

                       if (err.code === 'auth/user-not-found') {
                        message = Localization.getTextLocalization('loginSendEmailNotExists', language);
                       } else {
                        message = Localization.getTextLocalization('loginSendEmailError', language);
                       }

                        setResetPasswordForm({
                            isValid: false,
                            message: message
                        });
                   })
    }


    /**
     * 
     * Login du user
     * 
     * @param {*} e 
     */
    const onSubmit = e => {

        e.preventDefault();

        loginUser(formData.email, formData.password, language);
  
    }

    return (

        <div id="loginModal" className="">

            <Modal visible={visible} closemodal={() => closeModalCallback()}>   
                <form className="form" onSubmit={e => onSubmit(e)}>
                    <div className="login form-group">

                        <div className="login-title mt-2 pl-2">
                            <LocalizationHelper text="login"/>
                            <div className="login-sub-title mb-1">
                                <span style={{color: "#E55400"}} className="register-label ml-1">*</span> {' '}
                                <LocalizationHelper text="registerAllFields"/>
                            </div>
                        </div>


                        <span className="login-label ml-2 mt-2">
                            <LocalizationHelper text="registerEmail"/>
                        </span>
                        <span style={{color: "#E55400"}} className="login-label ml-1">*</span>
                        <div className="d-flex flex-row justify-content-center">
                            <input  required type="email" className="form-control ml-2 mt-1 login-input" 
                                    name="email"
                                    value={formData.email} 
                                    onChange={e => onChange(e)}/>
                        </div>

                        <span className="login-label ml-2 mt-2">
                            <LocalizationHelper text="registerPassword"/>
                        </span>
                        <span style={{color: "#E55400"}} className="login-label ml-1">*</span>
                        <div className="d-flex flex-row justify-content-center">
                            <input  required type="password" className="form-control ml-2 mt-1 login-input" 
                                    name="password"
                                    value={formData.password} 
                                    onChange={e => onChange(e)}
                                    minLength="6"
                                    maxLength="20"/>
                        </div>
                        
                        {loginInProgress &&

                            <div className="d-flex flex-row justify-content-center mt-3">
                                <span className="callCloudFunctionMessageOK blink_me">
                                    <LocalizationHelper text="loginInProgress"/> 
                                </span>
                                <img className="spinner" src={require('../../img/spinner.gif')} alt="" />
                            </div>    
                        }

                        {!loginInProgress && loginOk &&

                            <div className="d-flex flex-row justify-content-center mt-3 login-ok-message">
                                {message}
                            </div>
                        }

                        {!loginInProgress && loginFailed &&

                            <div className="d-flex flex-row justify-content-center mt-3 login-error-message">
                                <span dangerouslySetInnerHTML={{ __html: message }} />
                            </div>
                        }

                        <div className="d-flex flex-row justify-content-around mt-3">
                            <button disabled={(loginInProgress || loginOk) ? 'disabled' : ''}  type="submit" className="btn btn-success my-1">
                                <LocalizationHelper text="connection"/>    
                            </button>
                            <button disabled={(loginInProgress || loginOk)? 'disabled' : ''}  onClick={closeModalCallback} type="button" className="btn btn-danger my-1">
                                <LocalizationHelper text="cancel"/>    
                            </button>
                        </div>

                        <div className="d-flex flex-row justify-content-center mt-3">
                            <span className="small"><LocalizationHelper text="loginForgottenPassword1"/></span>
                            <span onClick={resetPassword} className="ml-1 small forgotten-password">
                                <LocalizationHelper text="loginForgottenPassword2"/>
                            </span>
                        </div>

                        {!resetPasswordForm.isValid && 
                        
                            <div className="d-flex flex-row justify-content-center mt-3 login-error-message">
                                {resetPasswordForm.message}
                            </div>
                        }

                        {sendPassword.resetSend && 

                             <div className="d-flex flex-row justify-content-center mt-3 login-ok-message">
                                {sendPassword.message}
                             </div>                       
                        }

                    </div>
                    
                </form>

            </Modal>

            {!loginInProgress && loginOk && (                      
                <Fragment>
                    {fadeOutModal()}
                </Fragment>
            )}

        </div>
    )
}

const mapStateToProps = state => ({
    language: state.config.language,
    loginInProgress: state.user.loginInProgress,
    loginOk: state.user.loginOk,
    loginFailed: state.user.loginFailed,
    message: state.user.message,
});

export default connect(mapStateToProps, {loginUser, resetLoginUser})(Login);
