import React, {useEffect, useState, Fragment} from 'react';
import '../../../css/admin.css';
import '../../../css/add-product.css';
import LocalizationHelper from '../../../helpers/LocalizationHelper';
import Localization from '../../../helpers/Localization';
import { addProduct } from '../../../redux/actions/productAction';
import { loadCategories } from '../../../redux/actions/categoryAction';
import { connect } from 'react-redux';
import Resizer from 'react-image-file-resizer';
import ModalProductPreview from './ModalProductPreview';
import EditorWrapper from '../../layout/EditorWrapper';

const initialFormData = {
    category: '',
    titleFR: '',
    titleEN: '',
    descriptionFR: '',
    descriptionEN: '',
    priceEuro: 0,
    priceDollar: 0,
    active: true,
    sold: false,
    news: false,
    imageUrl: [],
    firstImageIndex: 0,
    validForm: false
};

const inittialSelectedImage = {
    selectedImage: [],
    files: [] 
}


const AddProduct = ({categories, 
                    processAddInProgress,
                    processAddOk,
                    processAddFailed, 
                    language,
                    loadCategories,
                    addProduct}) => {
    
    // ref sur le input images qui est remplacé par un bouton
    let fileInputRef;
    let width = window.innerWidth;
    let topMargin = '0';

    useEffect(() => {
        loadCategories(language);
    }, [loadCategories, language]);

    /** State des données du formulaire */
    const [formData, setFormData] = useState({
        category: '',
        titleFR: '',
        titleEN: '',
        descriptionFR: '',
        descriptionEN: '',
        priceEuro: 0,
        priceDollar: 0,
        active: true,
        sold: false,
        news: false,
        imageUrl: [],
        firstImageIndex: 0,
        validForm: false
    });

     /** State des images sélectionnées et à uploader */
    const [selectedImage, setSelectedImage] = useState({
        selectedImage: [],
        files: []
    });

    const [showModal, setShowModal] = useState(false);

    const {category, titleFR, titleEN, priceEuro,
           priceDollar, active, sold, news, firstImageIndex} = formData;

    const {fileImage, files} = selectedImage;


    /**
     * 
     * Gestion du changement valeurs dans formulaire
     * 
     * @param {*} e 
     */
    const onChange = e => {
        const validFormVerif = isFormValid(e);

        if (e.target.name === "imageUrlCurrent") {
            setFormData({...formData, [e.target.name]: e.target.value, imageUrl: [e.target.value, ...formData.imageUrl]});
        } else {
            setFormData({...formData, [e.target.name]: e.target.value, validForm: validFormVerif });
        }
    }

    const onChangeDescriptionFR = value => {

        setFormData({...formData, descriptionFR: value});
    }

    const onChangeDescriptionEN = value => {

        setFormData({...formData, descriptionEN: value});
    }

    /**
     * 
     * Détermine si une catégorie a été sélectionnée
     * 
     * @param {*} e 
     */
    const isFormValid = (e) => {

        if ((e.target.name === "category")) {
            if (e.target.value.toString() !== "0") {
                return true;
            } else {
                return false;
            }
        } 
        return formData.validForm;
    }

    /**
     * 
     * Sauvegarde du product
     * 
     * @param {*} e 
     */
    const onSubmit = e => {

        e.preventDefault();
        const categorySelected = categories.find(cat => cat.idCat.toString() === formData.category);
        formData.category = categorySelected._id;
        formData.imageUrl = selectedImage.selectedImage;
        addProduct(formData);
    }

    /**
     * 
     * Re initialise formulraire après ajout OK
     * 
     */
    const resetForms = () => {

        setFormData({...initialFormData});
        setSelectedImage({...inittialSelectedImage});
        
    }

    /**
     * 
     * Fonction de rendu de la liste des catégories
     * 
     */
    const renderCategory = () => {

        if (categories) {
            return categories.filter(cat => cat.idCat >= 0).map((category, index) => {
                return <option key={index} value={category.idCat}>
                    {language === 'fr' && category.nameFR}
                    {language === 'en' && category.nameEN}
                </option>
            });
        }

    }

    /**
     * 
     * Charge les images sélectionnées dans le state et dans l'ihm
     * 
     * @param {*} e 
     */
    const loadImage = e => {

        console.log('loadImage');

        const filesURL = [];
        const filesToAdd = [];

        Array.from(e.target.files).forEach(fileAdd => {
            
            Resizer.imageFileResizer(
                fileAdd,
                800,
                800,
                'jpeg',
                65,
                0,
                uri => {

                    const fileResize = new File([uri], fileAdd.name, {
                        type: 'image/jpeg',
                        lastModified: Date.now()
                    });

                    filesURL.push(URL.createObjectURL(fileAdd));
                    filesToAdd.push(fileResize);

                    setSelectedImage({
                        selectedImage: [...selectedImage.selectedImage, ...filesToAdd],
                        files: [...selectedImage.files, ...filesURL]
                    });
                    
                },
                'blob'
            );
            
        });

    }

    /**
     * 
     * Click sur bouton "Supprimer" d'une image
     * 
     * @param {*} e 
     * @param {*} index 
     */
    const deleteImage = (e, index)=> {

        e.preventDefault();


        if (formData.firstImageIndex > index) {
            formData.firstImageIndex -= 1;
            console.log("delete formdata = ", formData)
        }

        const arraySelectedImages = Array.from(selectedImage.selectedImage);
        const selectedImageResult = [];
        arraySelectedImages.forEach((image, i) => {
            if (i !== index) {
                selectedImageResult.push(image);
            }
        });

        const filesUrlResult = [];
        selectedImage.files.forEach((fileUrl, i) => {
            if (i !== index) {
                filesUrlResult.push(fileUrl);
            }
        });

        setSelectedImage({
            selectedImage: selectedImageResult,
            files: filesUrlResult
        });

    }

    /**
     * 
     * Fonction de rendu de la liste des images sélectionnées
     * 
     */
    const renderImages = () => {

        if (files && files.length > 0) {
            return files.map((file, index) => {
                return (
                    <Fragment key={index} >
                        <div className="images-container">
                            <img className="file-image mt-3 ml-4" src={file} alt=""/>
                            <button onClick={(e) => deleteImage(e, index)} 
                                    className="btn btn-danger ml-3 mt-4 mb-4">
                                <LocalizationHelper text="delete"/>
                            </button>
                            <label className="ml-3 text-muted small">
                                <LocalizationHelper text="addProductFirstImage"/>
                            </label>
                            <input
                                className="ml-2"
                                type='radio'
                                value={index}
                                name='image'
                                key={index}
                                checked = {formData.firstImageIndex === index}
                                onChange={() => {
                                    setFormData({...formData, firstImageIndex: index});
                                    formData.firstImageIndex = index;
                                    console.log("formData = ", formData);
                                }}
                            />
                        )}
                        </div>
                    </Fragment>
                )
            });
        }
    }

    const closeModalCallback = () => {
        setShowModal(false);
    }

    const calculateTopMargin = () => {

        if (width < 500) {

            const value = 65 + (selectedImage.files.length - 1) * 30;
            topMargin = value.toString() + 'rem';
        }
    }

    return (
    <Fragment>
        {calculateTopMargin()}
        <div className="modal-preview" style={{top: topMargin}}>
            <span style={{display: showModal ? 'block': 'none'}}>
                <ModalProductPreview 
                    visible={showModal} 
                    language={language}
                    productDatas={formData}
                    fileImage={files[firstImageIndex]}
                    filesImages={files}
                    closeModalCallback={closeModalCallback}/>
            </span>
        </div>
        <div className="os-animation fadeInLeftSpeed mt-3 mb-4">
            <p className="text-header-form" >
                <LocalizationHelper text="addProductTitle"/>
            </p>
            <form className="form" onSubmit={e => onSubmit(e)}>
                <div className="form-group">
                    {categories && categories.length !==0 && (
                        <select name="category" value={category} onChange={e => {onChange(e);}}>
                            {renderCategory()}
                        </select>
                    )}
                    { (!categories || categories.length) ===0 && (
                        <img src={require('../../../img/spinner.gif')} alt="" width="32px"/> 
                    
                    )}
                </div>
                <div className="form-group">
                    <input  required 
                            style={{display: 'none'}}
                            type="file" 
                            multiple
                            className="form-control"
                            name="fileImage"
                            value={fileImage} 
                            ref={fileInput => fileInputRef = fileInput}
                            onChange={e => loadImage(e)}/>
                        <div>
                            <button className="btn btn-primary" 
                                    onClick={(e) => {e.preventDefault(); fileInputRef.click()}}>
                                <LocalizationHelper text="addProductPickFile"/>
                            </button>
                        </div>
                        {renderImages()}
                </div>
                <div className="form-group mb-4">
                    <div className="row">
                        <div className="col-6">
                            <input  required type="text" className="form-control input"
                                    placeholder={Localization.getTextLocalization("addProductPlaceholderTitleFR", language)} 
                                    name="titleFR"
                                    value={titleFR} 
                                    onChange={e => onChange(e)}/>
                        </div>
                        <div className="col-5">
                            <input  type="text" className="form-control input"
                                    placeholder={Localization.getTextLocalization("addProductPlaceholderTitleEN", language)} 
                                    name="titleEN"
                                    value={titleEN} 
                                    onChange={e => onChange(e)} />
                        </div>
                    </div>
                </div>
                <div className="form-group"> 

                    <EditorWrapper 
                        value={formData.descriptionFR}
                        onChange={onChangeDescriptionFR} 
                        placeholder={Localization.getTextLocalization("addProductPlaceholderDescriptionFR", 
                        language)}/>
     
                </div>
                <div className="form-group"> 

                    <EditorWrapper onChange={onChangeDescriptionEN} placeholder={Localization.getTextLocalization("addProductPlaceholderDescriptionEN", language)}/>
                    
                </div>
                <div className="form-group">
                    <div className="row ml-1">
                        <div className="">
                            <label className="label mr-2">{Localization.getTextLocalization("addProductPlaceholderPriceEuro", language)}</label>
                            <input  className="form-control input"
                                    required type="number" 
                                    placeholder={Localization.getTextLocalization("addProductPlaceholderPriceEuro", language)} 
                                    name="priceEuro"
                                    value={priceEuro} 
                                    onChange={e => onChange(e)} 
                                    maxLength="6"
                                    onFocus={() => {
                                        if (formData.priceEuro === 0) {
                                            setFormData({...formData, priceEuro: ''});
                                        }
                                    }}/>
                        </div>
                        <div className="dollar-field">
                            <label className="label mr-2">{Localization.getTextLocalization("addProductPlaceholderPriceDollar", language)}</label>
                            <input  className="form-control input"
                                    type="number" 
                                    placeholder={Localization.getTextLocalization("addProductPlaceholderPriceDollar", language)} 
                                    name="priceDollar"
                                    value={priceDollar} 
                                    onChange={e => onChange(e)} 
                                    maxLength="6"
                                    onFocus={() => {
                                        if (formData.priceDollar === 0) {
                                            setFormData({...formData, priceDollar: ''});
                                        }
                                    }}  />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    
                    <label className="label mr-2">{Localization.getTextLocalization("addProductPlaceholderActive", language)}</label>
                    <input value={active} onChange={e => {
                            setFormData({...formData, active: !active });
                            }} type="checkbox" name="active" checked={active} />
                    
                    <label className="label mr-2 ml-3">{Localization.getTextLocalization("addProductPlaceholderSold", language)}</label>
                    <input value={sold} onChange={e => {
                            setFormData({...formData, sold: !sold });
                            }} type="checkbox" name="sold" checked={sold} />
                    
                    <label className="label mr-2 ml-3">{Localization.getTextLocalization("addProductPlaceholderNews", language)}</label>
                    <input value={news} onChange={e => {
                            setFormData({...formData, news: !news });
                            }} type="checkbox" name="news" checked={news} />
              </div>
            <div className="row mb-2 ml-1">
                {processAddInProgress && (
                    <Fragment>
                        <span className="callCloudFunctionMessageOK blink_me">
                            <LocalizationHelper text="addProductInProgress"/> 
                        </span>
                        <img className="spinner" src={require('../../../img/spinner.gif')} alt="" />
                    </Fragment>    
                )}

                {!processAddInProgress && processAddOk && (
                    
                    <Fragment>
                        <span className="callCloudFunctionMessageOK ml-1">
                            <LocalizationHelper text="addProductOK"/> 
                        </span>
                    </Fragment>
                )}

                {!processAddInProgress && processAddFailed && (
                    
                    <Fragment>
                        <span className="callCloudFunctionMessageNOK ml-1">
                            <LocalizationHelper text="addProductNOK"/> 
                        </span>
                    </Fragment>
                )}

            </div>
                <button type="submit" disabled={formData.validForm ? '' : 'disabled'} className="btn btn-success my-1 mb-4">
                    <LocalizationHelper text="addProductButton"/>    
                </button>
                <button onClick={() => {setShowModal(true)}} type="button" disabled={formData.validForm ? '' : 'disabled'} className="btn btn-info my-1 mb-4 ml-2">
                    <LocalizationHelper text="addProductPreview"/>    
                </button>
                <div>
                <button onClick={() => resetForms()} type="button" disabled={formData.validForm ? '' : 'disabled'} className="btn btn-warning my-1 mb-4 ml-2">
                    <LocalizationHelper text="addProductReset"/>    
                </button>
                </div>
            </form>
        </div>        
    </Fragment>

    )
}

const mapStateToProps = state => ({
    categories: state.categories.categories,
    language: state.config.language,
    processAddInProgress: state.products.processAddInProgress,
    processAddOk: state.products.processAddOk,
    processAddFailed: state.products.processAddFailed

});

export default connect(mapStateToProps, {loadCategories, addProduct})(AddProduct);
