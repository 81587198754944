import { constants } from '../configuration/constants';

export default class UtilsHelper {


  static getFunctionUrl(functionName) {

    return  constants.EMULATORS ? constants[functionName + "_EMULATOR"] : constants[functionName] ;
  }

    /**
   * 
   * Tri des catégories
   * 
   * @param {*} categories 
   */
    static sortCategories(categoriesToSort, language) {

      const categoriesGeneric = categoriesToSort.filter(category => category.idCat < 0);
      let categories = categoriesToSort.filter(category => category.idCat > 0);
      let categoriesSelect = categoriesToSort.filter(category => category.idCat === 0);
      const keySort = language === 'fr' ? 'nameFR' : 'nameEN'; 
      categories.sort(this.compareValues(keySort));
      return [...categoriesSelect, ...categoriesGeneric, ...categories];
    }

    /**
     * 
     * Retrouve le nom/extension d'une url d'image
     * 
     * @param {*} imageUrl 
     */
    static getImageInfos(imageUrl) {

        const extension = imageUrl.substring(imageUrl.lastIndexOf('.') + 1);
        const nameTemp = imageUrl.substring(imageUrl.lastIndexOf('/') + 1, imageUrl.lastIndexOf('.'))
        const name = nameTemp.substring(nameTemp.indexOf('-') + 15);

        return {
            name,
            extension
        }
    }

    /**
     * 
     * Remplace les caractères spéciaux par "_", puis converti en majuscules
     * 
     * @param {*} text 
     */
    static removeSpecialCharacters(text) {
        if (!text) {
          return text;
        }
        const textTemp = text.replace(/(?!\w|\s)./g, '')
                             .replace(/\s+/g, ' ')
                             .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');;
        return  textTemp.toUpperCase();
    }

    static sanitizeCatagoryName(name) {
      if (!name) {
        return name;
      }
      const value = this.removeSpecialCharacters(name);
      return value.replace(' ', '');
    }

    /**
     * 
     * Tri dymanique liste suivant property key
     * 
     * @param {*} key 
     * @param {*} order 
     */
    static compareValues(key, order='asc') {
        return function(a, b) {
            if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
        }
  
      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA >= varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  /**
   * 
   * Détermine le masque de formattage à appliquer à appliquer au nombre
   * 
   * @param {*} numberValue 
   */
  static getNumberMaskFormat(numberValue) {

    const format = numberValue.toString().toLowerCase().split("");
 
    let formatMask = '';
    format.forEach((car, index) => {
        if (index !== 0 && index % 3 === 0) {
            formatMask += ' ';
        }
        formatMask += '#';
    });

    formatMask = formatMask.split("");
    formatMask = formatMask.reverse();
    formatMask = formatMask.join("");

    return formatMask;
  }
}