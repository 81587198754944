import React, {Fragment, useEffect} from 'react';
import { connect } from 'react-redux';
import ProductListItem from './ProductListItem';
import LocalizationHelper from './../../../helpers/LocalizationHelper';
import Localization from '../../../helpers/Localization';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetFlagDelete, fetchPaginationProducts } from '../../../redux/actions/productAction';

const ProductsList = ({
    language, 
    manage,
    products, 
    processInProgress, 
    processOk,
    processFailed,
    deleteOk,
    deleteFailed,
    resetFlagDelete,
    fetchPaginationProducts}) => {

    useEffect( () => {

        resetFlagDelete();

        window.addEventListener('scroll', listenToScroll);

        return () => {
            if (window) {
                window.removeEventListener('scroll', listenToScroll);
            }
        }

    }, [resetFlagDelete]);

    const listenToScroll = () => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
        //console.log(' scrolled = ', scrolled);

        if (scrolled === 1) {
            //fetchPaginationProducts();
        }
      }

    /**
     * 
     * Fonction de rendu de la liste des produits retournés par la base
     * 
     */
    const renderProducts = () => {

        if (products && products.length > 0) {
            return products.map(product => {
                return <ProductListItem product={product} key={product._id}
                                        language={language} manage={manage}/>
            });
        }
    }

    /**
     * 
     * Affiche toaster success pour suppession produit
     * 
     */
    const showToastSuccess = () => {
        toast.success(Localization.getTextLocalization("productDeleteOK", language), {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
            });
    }

    return (

        <div className="row mb-4 mx-1">

            {processInProgress && (
                <Fragment>
                    <span className="callCloudFunctionMessageOK blink_me">
                        <LocalizationHelper text="fetchProductInProgress"/> 
                    </span>
                    <img className="spinner" src={require('../../../img/spinner.gif')} alt="" />
                </Fragment>    
            )}

            {processFailed && (
                    
                <Fragment>
                    <span className="callCloudFunctionMessageNOK ml-1">
                        <LocalizationHelper text="fetchProductNOK"/> 
                    </span>
                </Fragment>
            )}
            
            {processOk && renderProducts()}

            {deleteOk && (
                    
                <Fragment>
                    {showToastSuccess()}
                    <ToastContainer />
                </Fragment>
            )}

        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products.products,
    language: state.config.language,
    processInProgress: state.products.processInProgress,
    processOk: state.products.processOk,
    processFailed: state.products.processFailed,
    deleteInProgress: state.products.deleteInProgress,
    deleteOk: state.products.deleteOk,
    deleteFailed: state.products.deleteFailed
});
export default connect(mapStateToProps, {resetFlagDelete, fetchPaginationProducts})(ProductsList);
