import { CHANGE_LANGUAGE, FETCH_ADDRESS_LOCATIONS_OK } from './types';
import axios from 'axios';
import { constants } from '../../configuration/constants';

export const setLanguage = (language) => dispatch => {

    dispatch({
        type: CHANGE_LANGUAGE,
        payload: language
    });
}

export const fetchAdressLocations = (language) => async dispatch => {

    try {

        const addressLocations = await axios.get(constants.URL_CLOUD_ADDRESS_LOCATIONS);
        
        dispatch({
            type: FETCH_ADDRESS_LOCATIONS_OK,
            payload: {
                addressLocations: addressLocations.data
            }
        });
    } catch (error) {
        
    }
}