import { TEXT_LOCALIZATION } from '../configuration/TextLocalization';

export default class Localization {


        // localise le texte suvant la locale
        static getTextLocalization(text, language) {

            const localisationText = text + '_' + language;
            let localisation = TEXT_LOCALIZATION[localisationText];
            if (localisation == null || localisation === undefined || localisation === "") {
                localisation = TEXT_LOCALIZATION[text + '_en'];
            }
    
            return localisation;
        } 
}