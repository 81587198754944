import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducers from './redux/reducers';

const initialState = {};
const middleWare = [thunk];

const store = createStore(reducers, 
                          initialState, 
                         composeWithDevTools(applyMiddleware(...middleWare)));

export default store;