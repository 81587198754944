import { REGISTER_USER_IN_PROGRESS, REGISTER_USER_OK, REGISTER_USER_FAILED,
         REGISTER_RESET_OK, USER_ALREADY_AUTHENTICATED, USER_LOGOUT, LOGIN_USER_IN_PROGRESS,
         LOGIN_USER_OK, LOGIN_USER_FAILED, LOGIN_RESET_OK, UPDATE_USER_IN_PROGRESS,
         UPDATE_USER_OK, UPDATE_USER_FAILED } from './types';

import firebaseApp from './../../firebase';
import axios from 'axios';

import Localization from './../../helpers/Localization';
import { constants } from './../../configuration/constants';
import UtilsHelper from '../../helpers/UtilsHelper';

/**
 * 
 * Création utilisateur
 * 
 * - une partie dans firebase auth
 * - autre partie dans firestore
 * 
 * @param {*} user 
 */
export const registerUser = (user, language) => async dispatch => {

    dispatch({type: REGISTER_USER_IN_PROGRESS});

    let stepRegister = 1;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {

        user.displayName = user.firstName + ' ' + user.name;

        const firebaseUser = await firebaseApp.auth().createUserWithEmailAndPassword(user.email, user.password);
        const displayName = user.firstName + '-' + user.name;
        const photoURL = user.adress;
        const phoneNumber = user.phoneNumber;
        await firebaseUser.user.updateProfile({displayName, photoURL, phoneNumber});
        console.log("firebaseUser : ", firebaseUser);

        stepRegister = 2;

        user.uid = firebaseUser.user.uid;
        user.createdOn = Date.now();
        user.updatedOn = Date.now();
        const body =  JSON.stringify(user);
        await axios.post(UtilsHelper.getFunctionUrl("URL_CLOUD_USER_REGISTER"), body, config);

        dispatch({
            type: REGISTER_USER_OK,
            payload: {
                user: user,
                message: Localization.getTextLocalization('registerOk', language)
            }
        });

    } catch (error) {
        
        console.log("registerUser error  = ", error);
        let message = Localization.getTextLocalization('registerFailed', language)
        if (error.code === 'auth/email-already-in-use') {
            message += '<br/>' + Localization.getTextLocalization('registerFailedEmail', language);
        }

        // suppression dans firestore si erreur dans firebase auth
        if (stepRegister === 2) {

            const user = firebaseApp.auth().currentUser;
            user.delete();

            //body =  JSON.stringify(user.email);
            //await axios.post(constants.URL_CLOUD_USER_DELETE, body, config);
        }

        dispatch({
            type: REGISTER_USER_FAILED,
            payload: message
        });

        
        dispatch({
            type: USER_LOGOUT
        });
    }
};

/**
 * 
 * Reset du flag registerOk pour question affichage
 * 
 * 
 */
export const resetRegisterUser = () => async dispatch => {
    dispatch({
        type: REGISTER_RESET_OK,
    });
}

/**
 * 
 * Utilisateur déjà authentifié à l'arrivée sur l'appli
 * 
 */
export const userAlreadyAuthenticated = user => async dispatch => {

    // récupération information complémentaire
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const findUser = await axios.post(UtilsHelper.getFunctionUrl("URL_CLOUD_GET_USER_INFOS"), {uid: user.uid}, config);
    let userDatas = findUser.data.userDatas;
    if (!userDatas) {
        return;
    }
    userDatas.uid = user.uid;

    dispatch({
        type: USER_ALREADY_AUTHENTICATED,
        payload: userDatas
    })

}

/**
 * 
 * Déconnexion Utilisateur 
 * 
 */
export const logoutUser = () => async dispatch => {

    dispatch({
        type: USER_LOGOUT,
    })

}

/**
 * 
 * Connexion Utilisateur 
 * 
 */
export const loginUser = (email, password, language) => async dispatch => {
    
    dispatch({type: LOGIN_USER_IN_PROGRESS});

    try {
        
        await firebaseApp.auth().signInWithEmailAndPassword(email, password);

        dispatch({
            type: LOGIN_USER_OK,
            payload: Localization.getTextLocalization('loginOk', language)
        });

    } catch (error) {

        console.log("loginUser error  = ", error);
        let message = Localization.getTextLocalization('loginFailed', language)
        if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
            message += '<br/>' + Localization.getTextLocalization('loginFailedWrong', language);
        }
        dispatch({
            type: LOGIN_USER_FAILED,
            payload: message
        });
    }
   
}

/**
 * 
 * Reset du flag loginOk pour question affichage
 * 
 * 
 */
export const resetLoginUser = () => async dispatch => {
    dispatch({
        type: LOGIN_RESET_OK,
    });
}

/**
 * 
 * Mise à jour utilisateur
 * 
 * @param {*} user 
 */
export const updateUser = (user, language) => async dispatch => {

    console.log("updateUser user = ", user);
    dispatch({type: UPDATE_USER_IN_PROGRESS});

    try {
        
        // récupération infos user connecté
        const userAuth = firebaseApp.auth().currentUser;

        // mise à jour email dans firebase auth
        if (userAuth.email !== user.email) {
            await userAuth.updateEmail(user.email);
        }

        // mise à jour autres informations dans firebase auth
        await userAuth.updateProfile({
            displayName: user.firstName + '-' + user.name,
            photoURL: user.address,
            phoneNumber: user.phoneNumber
        });

        // mise à jour dans firestore
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        user.updatedOn = Date.now();
        user.uid = userAuth.uid;
        const body =  JSON.stringify(user);
        await axios.post(constants.URL_CLOUD_USER_UPDATE, body, config);         

        dispatch({
            type: UPDATE_USER_OK,
            payload: {
                message: Localization.getTextLocalization('acountUserUpdateOk', language),
                user
            }
        });

    } catch (error) {

        console.log('updateUser error = ', error);

        let message = Localization.getTextLocalization('acountUserUpdateFailed', language)
        if (error.code === 'auth/requires-recent-login') {
            message += '<br/>' + Localization.getTextLocalization('acountUserUpdateReconnect', language);
        }

        if (error.code === 'auth/email-already-in-use') {
            message += '<br/>' + Localization.getTextLocalization('registerFailedEmail', language);
        }

        dispatch({
            type: UPDATE_USER_FAILED,
            payload: {
                user,
                message
            }
        });
    }

}