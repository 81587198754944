import { EMAIL_IN_PROGRESS, EMAIL_SEND_OK, EMAIL_SEND_FAIL } from '../actions/types';


const initialState = {
    sendingInProgress: false,
    sendOk: false
}

export default function(state = initialState, action) {

    const {type} = action;

    switch(type) {

        case EMAIL_IN_PROGRESS:
            return {...state, sendingInProgress: true};

        case EMAIL_SEND_OK:
            return {...state, sendingInProgress: false, sendOk: true};

        case EMAIL_SEND_FAIL:
            return {...state, sendingInProgress: false, sendOk: false};

        default:
            return state;
    }
}