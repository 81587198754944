import { CHANGE_LANGUAGE, FETCH_ADDRESS_LOCATIONS_OK } from '../actions/types';
import Localization from '../../helpers/Localization'; 

const initialState = {
    language: navigator.language.indexOf("fr") !== -1 ? "fr" : "en",
    addressLocations: []
}

export default function(state=initialState, action) {

    const {type, payload} = action;

    switch(type) {

        case CHANGE_LANGUAGE:

            const finalLanguage = payload.indexOf("fr") !== -1 ? "fr" : "en";
            Localization.language = finalLanguage;
            return {...state, language: finalLanguage};

        case FETCH_ADDRESS_LOCATIONS_OK: 
            return {...state, addressLocations: payload.addressLocations};

        default:
            return state;
    }
}