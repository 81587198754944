import axios from 'axios';
import { ADD_PRODUCT_OK, ADD_PRODUCT_FAIL, ADD_PRODUCT_IN_PROGRESS, 
        FETCH_PRODUCT_IN_PROGRESS, FETCH_PRODUCT_OK, UPDATE_PRODUCT_IN_PROGRESS, 
        UPDATE_PRODUCT_OK, UPDATE_PRODUCT_FAIL, UPDATE_PRODUCT_CHECKBOX,
         FETCH_PRODUCT_FAIL, SEARCH_PRODUCTS, SEARCH_PRODUCTS_IN_PROGRESS, 
         FETCH_PRODUCT_BY_ID, DELETE_PRODUCT_IN_PROGRESS, DELETE_PRODUCT_OK, 
         DELETE_PRODUCT_FAIL, DELETE_PRODUCT_RESET, FETCH_PAGINATION_PRODUCTS } from './types';
import { constants } from './../../configuration/constants';
import UtilsHelper from '../../helpers/UtilsHelper';

/**
 * 
 * Ajout produit en base 
 * 
 * 
 * @param {*} product 
 */
export const addProduct = product => async dispatch => {

    dispatch({type: ADD_PRODUCT_IN_PROGRESS});

    try {

        /** ajout des images : on ajoute chaque File image au FormData */
        let formDataImages = new FormData();
        formDataImages.set('title', UtilsHelper.removeSpecialCharacters(product.titleFR));
        const arraySelectedImages = Array.from(product.imageUrl);
        const imageNamesOrderIhm = [];
        arraySelectedImages.forEach(image => {
            formDataImages.set(image.name, image, image.name);
            imageNamesOrderIhm.push(image.name);
        });

        // appel Cloud Function d'upload image puis mise à jour du tableau des url
        // des images avec url cloud storage
        const imageUrlGS = await axios.post(constants.URL_CLOUD_UPLOAD_FILE, formDataImages);
        
        // ATTENTION : l'ordre des images uploadées dans le bucket n'est pas forcèment celui des images
        // dans l'ihm, cela dépend du poid des images => remettre dans le bon ordre
        let imagesUrlFinal = [];
        imageNamesOrderIhm.forEach(imageName => {
            imageUrlGS.data.forEach(imageBucket => {
                if (imageBucket.includes(imageName)) {
                    imagesUrlFinal.push(imageBucket);
                }
            })
        });
        
        product.imageUrl = imagesUrlFinal;
        

        // ajout du produit;
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body =  JSON.stringify(product);
        const productAdded = await axios.post(UtilsHelper.getFunctionUrl("URL_CLOUD_ADD_PRODUCT"), body, config);

        dispatch({
            type: ADD_PRODUCT_OK,
            payload: productAdded
        });

    } catch (error) {

        console.error('error addProduct = ', error);

        dispatch({
            type: ADD_PRODUCT_FAIL
        });
    }
}

/**
 * 
 * Ajout produit en base 
 * 
 * 
 * @param {*} product 
 */
export const updateProduct = product => async dispatch => {

    dispatch({type: UPDATE_PRODUCT_IN_PROGRESS});

    try {

        /** ajout des images : on ajoute chaque File image au FormData */
        let formDataImages = new FormData();
        formDataImages.set('title', UtilsHelper.removeSpecialCharacters(product.titleFR));
        const arraySelectedImages = Array.from(product.imageUrl);
        const imageNamesOrderIhm = [];
        arraySelectedImages.forEach(image => {
            formDataImages.set(image.name, image, image.name);
            imageNamesOrderIhm.push(image.name);
        });

        // appel Cloud Function d'upload image puis mise à jour du tableau des url
        // des images avec url cloud storage
        const imageUrlGS = await axios.post(constants.URL_CLOUD_UPLOAD_FILE, formDataImages);

        // ATTENTION : l'ordre des images uploadées dans le bucket n'est pas forcèment celui des images
        // dans l'ihm, cela dépend du poid des images => remettre dans le bon ordre
        let imagesUrlFinal = [];
        imageNamesOrderIhm.forEach(imageName => {
            imageUrlGS.data.forEach(imageBucket => {
                if (imageBucket.includes(imageName)) {
                    imagesUrlFinal.push(imageBucket);
                }
            })
        });

        product.imageUrl = imagesUrlFinal;


        // modification du produit;
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body = JSON.stringify(product);
        await axios.post(UtilsHelper.getFunctionUrl("URL_CLOUD_UPDATE_PRODUCT"), body, config);

        dispatch({
            type: UPDATE_PRODUCT_OK,
            payload: product.imageUrl
        });

    } catch (error) {

        console.error('error update product = ', error);

        dispatch({
            type: UPDATE_PRODUCT_FAIL
        });
    }
}

/**
 * 
 * update les checkbox d'un product
 * 
 * 
 */
export const updateProductCheckbox = productCheckbox => async dispatch => {

    try {

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body = JSON.stringify(productCheckbox);
        await axios.post(UtilsHelper.getFunctionUrl("URL_CLOUD_UPDATE_CHECKBOX_PRODUCT"), body, config);
        
        dispatch({
            type: UPDATE_PRODUCT_CHECKBOX,
            payload: productCheckbox
        });
        
    } catch (error) {
        
        console.error('error update checkbox product = ', error);

        dispatch({
            type: UPDATE_PRODUCT_FAIL
        });
    }
}

/**
 * 
 * Recherche tous les products en base
 * 
 */
export const fetchProducts = manage => async dispatch => {

    dispatch({type: FETCH_PRODUCT_IN_PROGRESS});

    try {

        const products = await axios.post(UtilsHelper.getFunctionUrl("URL_CLOUD_FETCH_PRODUCT"), {active: !manage});

        dispatch({
            type: FETCH_PRODUCT_OK,
            payload: products.data
        });
        
    } catch (error) {

        console.error('error fetchProducts = ', error);

        dispatch({
            type: FETCH_PRODUCT_FAIL
        });
    }

}


/**
 * 
 * Recherche un produit par son id
 * 
 */
export const fetchProductByID = (productId) => async dispatch => {

    dispatch({type: FETCH_PRODUCT_IN_PROGRESS});

    try {

        const product = await axios.post(UtilsHelper.getFunctionUrl("URL_CLOUD_FETCH_PRODUCT_BY_ID"), {id: productId});

        dispatch({
            type: FETCH_PRODUCT_BY_ID,
            payload: product.data
        });
        
    } catch (error) {

        console.error('error fetchProductByID = ', error);

        dispatch({
            type: FETCH_PRODUCT_FAIL
        });
    }

}
/**
 * 
 * Suppression d'un produit par son id
 * 
 * @param {*} idProduct 
 */
export const deleteProduct = idProduct => async dispatch => {

    dispatch({
        type: DELETE_PRODUCT_IN_PROGRESS,
        payload: idProduct
    });

    try {
        
        const product = await axios.post(UtilsHelper.getFunctionUrl("URL_CLOUD_DELETE_PRODUCT"), {id: idProduct});

        dispatch({
            type: DELETE_PRODUCT_OK,
            payload: product.data.id
        });

    } catch (error) {

        console.error('error delete product = ', error);

        dispatch({
            type: DELETE_PRODUCT_FAIL
        });
    }
}

/**
 * 
 * Réintialisation du flag delete 
 * Nécessaire si plusieurs delete à la suite
 * 
 */
export const resetFlagDelete = () => dispatch => {
    dispatch({
        type: DELETE_PRODUCT_RESET
    });
}

/**
 * Effectue une recherche des produits par mot-clés
 * 
 * @param {*} keywords 
 * @param {*} productsOriginal 
 * @param {*} language 
 * @param {*} manage 
 */
export const searchProducts = (keywords, productsOriginal, language, manage) => async dispatch => {

    dispatch({type: SEARCH_PRODUCTS_IN_PROGRESS});

    if (!keywords || keywords.trim().length === 0) {
        dispatch({
            type: SEARCH_PRODUCTS,
            payload: {
                products: [],
                resultOfSearch: false
            }
        });
        return;
    }

    /*try {

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const searchBody = {
            keywords,
            manage
        }
        const body =  JSON.stringify(searchBody);
        const products = await axios.post(constants.URL_CLOUD_SEARCH_PRODUCT, body, config);

        console.log("searchProducts products = ", products.data);
        
        dispatch({
            type: SEARCH_PRODUCTS,
            payload: products.data
        });

    } catch (error) {
        
    }*/

    let arrayKeywords = keywords.split(";");
    arrayKeywords = arrayKeywords.map(keyword => {
        keyword = keyword.trim();
        if (keyword !== "") {
            return keyword.toUpperCase();
        } else {
            return keyword;
        }
    });

    setTimeout(() => {
        const productsResult = productsOriginal.filter(product => {
            
                const title = language === 'fr' ? product.titleFR : product.titleEN;
                const description = language === 'fr' ? product.descriptionFR : product.descriptionEN;
                let searchStringTemp = title + " " + description;
                searchStringTemp = searchStringTemp.replace('’', ' ');
                searchStringTemp = searchStringTemp.replace(',', ' ');
                searchStringTemp = searchStringTemp.replace('\n', ' ');
                searchStringTemp = searchStringTemp.replace('.', ' ');
                const searchString = searchStringTemp.toUpperCase();
                
                return arrayKeywords.some(keyword => {
                    return searchString.includes(keyword)
                });
                
            }
        
        );
    
        const porductsFinal = productsResult.filter(function( product ) {return product !== undefined;})
        dispatch({
            type: SEARCH_PRODUCTS,
            payload: {
                products: porductsFinal,
                resultOfSearch: true
            }
        });

        /*dispatch({
            type: SEARCH_PRODUCTS,
            payload: productsResult.filter(function( product ) {
                                return product !== undefined;
                    })
        });*/
    }, 200);
    
}

export const fetchPaginationProducts = () => async dispatch => {

    dispatch({
        type: FETCH_PAGINATION_PRODUCTS
    })
}