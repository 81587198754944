import React, {useState} from 'react';
import LocalizationHelper from '../../helpers/LocalizationHelper';
import '../../css/account.css';
import PersonnalInformations from './PersonnalInformations';
import Orders from './Orders';
import {connect} from 'react-redux';

function AccountHome({
    name,
    firstName
}) {

    const [profilTabActive, setProfilTabActive] = useState(true);
    const [orderTabActive, setOrderTabActive] = useState(false);

    const classProfil = profilTabActive ? 'nav-link account-active-link' : 'nav-link account-custom-link';
    const classOrder = orderTabActive ? 'nav-link tab-item-mt account-active-link' : 'nav-link tab-item-mt account-custom-link';

    return (
        <div className="bg-light os-animation fadeInUp acccount">
            <div className="row">
                <div className="col text-center pt-4 mx-2">
                    <h1 className="text-secondary font-italic account-title">
                        {firstName + ' ' + name} {' : '} <LocalizationHelper text="accountWelcome"/>
                    </h1>
                </div>           
            </div>
            <ul className="nav nav-tabs mt-4">

                <li className="nav-item ml-1">
                    <span onClick={() => {
                            setProfilTabActive(true);
                            setOrderTabActive(false);
                            }}  
                            className={classProfil} 
                            style={{border: "none"}}>
                            <span className="account-tab-title">
                                <LocalizationHelper text="accountTabInfo" />
                            </span>
                    </span>
                </li>
                <li className="nav-item ml-1">
                    <span onClick={() => {
                            setProfilTabActive(false);
                            setOrderTabActive(true);
                            }}  
                            className={classOrder} 
                            style={{border: "none"}}>
                            <span className="account-tab-title">
                                <LocalizationHelper text="accountTabOrder" />
                            </span>
                    </span>
                </li>

            </ul>

            {profilTabActive && <PersonnalInformations/>}
            {orderTabActive && <Orders/>}

        </div>
    )
}

const mapStateToProps = state => ({
    name: state.user.userDatas === undefined ? '' : state.user.userDatas.name,
    firstName: state.user.userDatas === undefined ? '' : state.user.userDatas.firstName
});

export default connect(mapStateToProps, {})(AccountHome);
