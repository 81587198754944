import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import LocalizationHelper from '../../helpers/LocalizationHelper';
import '../../css/personnal-infos.css';
import { updateUser } from './../../redux/actions/userAction';
import { fetchAdressLocations } from '../../redux/actions/configAction';

function PersonnalInformations({
    language,
    user,
    updateUserInProgress,
    updateUserOk,
    updateUserFailed,
    message,
    updateUser,
    fetchAdressLocations,
    addressLocations
}) {

    useEffect(() => {

        if (user) {      

            setFormData({
                email: user.email,
                name: user.name,
                firstName: user.firstName,
                address: user.address,
                addressLocation: user.addressLocation,
                phoneNumber: user.phoneNumber,
                uid: user.uid
            });

            fetchAdressLocations();
        }

        

    }, [user, fetchAdressLocations]);

    const [formData, setFormData] = useState({
        email: user ? user.email : '',
        name: '',
        firstName: '',
        address: '',
        addressLocation: '',
        password: '',
        confirmPassword: '',
        isValidForm: true,
        profile: 'customer',
        message: '',
        uid: ''
    });

    const onChange = e => {
        
        setFormData({...formData, [e.target.name]: e.target.value});
    }

        /**
     * 
     * Fonction de rendu de la liste des catégories
     * 
     */
    const renderAddressLocations = () => {

        if (addressLocations) {
            return addressLocations.map((adressLocation, index) => {
                return <option key={index} value={adressLocation._id}>
                    {language === 'fr' && adressLocation.nameFR}
                    {language === 'en' && adressLocation.nameEN}
                </option>
            });
        }

    }

    /**
     * 
     * mise à jour du user
     * 
     * @param {*} e 
     */
    const onSubmit = e => {

        e.preventDefault();

        console.log("formdata = ", formData);
        updateUser(formData, language);
    }

    return (

        <div className="os-animation fadeInUp mt-3 mb-5 personnal-infos">
            <div className="personnal-banner">
                <h6 className="text-black ml-2 py-3 text-center" >
                    <LocalizationHelper text="personnalInfosTitle"/>
                </h6>
            </div>
            <form className="form" onSubmit={e => onSubmit(e)}>
                <div className="personnal-block">
                    <span className="register-label ml-2">
                        <LocalizationHelper text="registerEmail"/>
                    </span>
                    <span style={{color: "#E55400"}} className="register-label ml-1">*</span>
                    <div className="d-flex flex-row justify-content-center">
                        <input  required type="email" className="form-control ml-2 mt-1 register-input" 
                                name="email"
                                value={formData.email} 
                                onChange={e => onChange(e)}
                                minLength="4"
                                maxLength="60"/>
                    </div>
                </div>

                <div className="personnal-block">
                    <span className="register-label ml-2">
                        <LocalizationHelper text="registerName"/>
                    </span>
                    <span style={{color: "#E55400"}} className="register-label ml-1">*</span>
                    <div className="d-flex flex-row justify-content-center">
                        <input  required type="text" className="form-control ml-2 mt-1 register-input" 
                                name="name"
                                value={formData.name} 
                                onChange={e => onChange(e)}
                                minLength="2"
                                maxLength="50"/>
                    </div>
                </div>

                <div className="personnal-block">
                    <span className="register-label ml-2">
                        <LocalizationHelper text="registerFirstName"/>
                    </span>
                    <span style={{color: "#E55400"}} className="register-label ml-1">*</span>
                    <div className="d-flex flex-row justify-content-center">
                        <input  required type="text" className="form-control ml-2 mt-1 register-input" 
                                name="firstName"
                                value={formData.firstName} 
                                onChange={e => onChange(e)}
                                minLength="2"
                                maxLength="50"/>
                    </div>
                </div>

                <div className="personnal-block">
                    <span className="register-label ml-2">
                        <LocalizationHelper text="registerAddress"/>
                    </span>
                    <span style={{color: "#E55400"}} className="register-label ml-1">*</span>
                    {addressLocations && addressLocations.length !==0 && (
                        <select required className="ml-4 mt-2 mb-2 option-label" name="addressLocation" value={formData.addressLocation} onChange={e => {onChange(e);}}>
                            {renderAddressLocations()}
                        </select>
                    )}
                    
                    <div className="d-flex flex-row justify-content-center">
                        <textarea  className="form-control ml-2 mt-1 register-input" 
                                    name="address"
                                    rows="2"
                                    value={formData.address} 
                                    onChange={e => onChange(e)}
                                    minLength="2"
                                    maxLength="150"/>
                    </div>
                </div>


                <div className="personnal-block">
                    <span className="register-label ml-2">
                        <LocalizationHelper text="registerPhoneNumber"/>
                    </span>
                    <div className="d-flex flex-row justify-content-center">
                        <input  type="tel" className="form-control ml-2 mt-1 register-input" 
                                name="phoneNumber"
                                value={formData.phoneNumber} 
                                onChange={e => onChange(e)}
                                minLength="6"
                                maxLength="16"/>
                    </div>
                </div>
                <div className="d-flex flex-column align-items-center mt-3">
                                            
                    {updateUserInProgress &&

                        <div className="d-flex flex-row justify-content-center mt-3 mb-3">
                            <span className="callCloudFunctionMessageOK blink_me">
                                <LocalizationHelper text="acountUserUpdateInProgress"/> 
                            </span>
                            <img className="spinner" src={require('../../img/spinner.gif')} alt="" />
                        </div>    
                    }
                    {!updateUserInProgress && updateUserOk &&

                        <div className="d-flex flex-row justify-content-center mt-3 mb-3 login-ok-message">
                            {message}
                        </div>
                    }

                    {!updateUserInProgress && updateUserFailed &&

                        <div className="d-flex flex-row justify-content-center mt-3 mb-3 login-error-message">
                            <span dangerouslySetInnerHTML={{ __html: message }} />
                        </div>
                    }
                    <button type="submit" className="btn btn-success mt-3">
                        <LocalizationHelper text="personalUpdate"/>    
                    </button>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    language: state.config.language,
    user: state.user.userDatas,
    updateUserInProgress: state.user.updateUserInProgress,
    updateUserOk: state.user.updateUserOk,
    updateUserFailed: state.user.updateUserFailed ,
    message: state.user.message,
    addressLocations: state.config.addressLocations
});

export default connect(mapStateToProps, {fetchAdressLocations, updateUser})(PersonnalInformations);
