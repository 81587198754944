
import '../css/contact.css';

import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import LocalizationHelper from '../helpers/LocalizationHelper';
import ReCAPTCHA from "react-google-recaptcha";
import { constants } from '../configuration/constants';
import Localization from '../helpers/Localization';

import { sendMail } from './../redux/actions/emailAction';
import { saveMessage } from './../redux/actions/messageAction';

const Contact = ({sendMailStatus, sendMail, saveMessage, language}) => {

    /** définition du state */
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        captcha: false
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { name, email, message } = formData;

    /** callback changement valeur formulaire => mise à jour du state */
    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    /** callback sur click Captcha */
    const onChangeCaptcha = value => {
        setFormData({ ...formData, captcha: true});
    }

    /** soumission formulaire */
    const onSubmit = e => {

        e.preventDefault();

        sendMail({name, email, message});
        saveMessage({name, email, message});

        setFormData({
            name: "",
            email: "",
            message: ""
        });
    };

    return (

        <section className="os-animation rotateInDownLeft contact">
                <div className="row">
                    <div className="col-lg-5 justify-content-center contact-title">
                        <h3 className="display-4 mb-2 text-center text-white">
                            <LocalizationHelper text="contactUs"/>
                        </h3>
                    </div>
                </div>
                <div className="mt-2 os-animation rotateInDownLeft">
                    <i className="fas fa-phone text-success ml-4"></i> 
                    <p className="ml-4 mt-2"> <span className="contact-name">jacques DIO : </span><span className="contact-value">06 81 31 54 99</span></p>
                </div>
                <div className="row justifiy-content-start os-animation fadeInLeft">
                    <hr className="contact-separator"></hr>
                </div>
                <div className="mt-4 os-animation rotateInDownLeft">
                    <i className="fas fa-at text-primary ml-4"></i> 
                    <p className="ml-4 mt-2"> <span className="contact-name"></span><span className="contact-value">antiquecharmedeco@hotmail.fr</span></p>
                </div>


                <div className="row">
                    <div className="col-lg-5 justify-content-center contact-message">
                        <h3 className="display-4 mb-2 text-center text-white">
                            <LocalizationHelper text="contactMessage"/>
                        </h3>
                    </div>
                </div>
                <div className="row os-animation fadeInUp">
                    <form className="form contact-form" onSubmit={e => {
                        onSubmit(e);
                    }
                    }>
                        <div className="column mb-4">
                            <span className="text-warning">
                                <LocalizationHelper text="contactCaptcha"/>
                            </span>
                            <ReCAPTCHA
                                sitekey={constants.CAPTCHA_KEY}
                                size="compact"
                                theme="dark"
                                onChange={onChangeCaptcha}
                            />
                        </div>
                        <div className="form-group">
                            <input 
                                disabled={!formData.captcha ? 'disabled': ''}
                                className="form-control my-2 p-2 input"
                                value={name} 
                                onChange={e => onChange(e)} 
                                type="text" 
                                placeholder={Localization.getTextLocalization("contactYourName", language)}
                                name="name" 
                                required />
                        </div>
                        <div className="form-group">
                            <input 
                                disabled={!formData.captcha ? 'disabled': ''}
                                className="form-control my-2 input"
                                value={email}
                                onChange={e => onChange(e)}
                                type="email" 
                                placeholder={Localization.getTextLocalization("contactYourMail", language)}
                                name="email" 
                                required />
                        </div>
                        <div className="form-group py-2 mt-2"> 
                            <textarea 
                                disabled={!formData.captcha ? 'disabled': ''}
                                className="form-control" 
                                value={message}
                                name="message"
                                onChange={e => onChange(e)}
                                rows="4" 
                                placeholder={Localization.getTextLocalization("contactYourMessage", language)}
                                required
                                maxLength="250">
                            </textarea>       
                        </div>
                        {
                            sendMailStatus.sendingInProgress && (
                                <p className="callCloudFunctionMessage blink_me">
                                    <LocalizationHelper text="contactSending"/>
                                </p>
                            )
                        }

                        {
                            !sendMailStatus.sendingInProgress && sendMailStatus.sendOk && (
                                <p className="callCloudFunctionMessageOK">
                                    <LocalizationHelper text="contactSendingOK"/>
                                </p>
                            )
                        } 

                        <button disabled={sendMailStatus.sendingInProgress || !formData.captcha ? 'disabled' : ''} 
                                type="submit" className="btn btn-primary btn-contact">
                                    <LocalizationHelper text="contactSend" />
                        </button>  
                        
                    </form>
                </div>
                
        </section>
    )
}

const mapStateToProps = state => ({
    sendMailStatus: state.sendMail,
    language: state.config.language
});

export default connect(mapStateToProps, {sendMail, saveMessage})(Contact);
