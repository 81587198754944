import { ADD_TO_CART, REMOVE_FROM_CART } from './types';

/**
 * 
 * Ajout d'un produit au panier
 * 
 * @param {*} product 
 */
export const addToCart = product => async dispatch => {

    dispatch({
        type: ADD_TO_CART,
        payload: product
    });
}

/**
 * 
 * Suppression d'un produit au panier
 * 
 * @param {*} product 
 */
export const removeFromCart = product => async dispatch => {


    dispatch({
        type: REMOVE_FROM_CART,
        payload: product
    });
}
