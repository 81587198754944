import React, {Fragment, useState, useEffect} from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './css/style.css';
import './css/animate.css';

// composants
import Navbar from './components/layout/Navbar';
import Home from './components/Home';
import Shop from './components/shop/Shop';
import About from './components/About';
import Customers from './components/Customers';
import Contact from './components/Contact'
import Admin from './components/admin/Admin';
import ScrollToTop from './helpers/ScrollToTop';
import CartDetail from './components/Cart/CartDetail';
import ProtectedRoutes from './components/layout/ProtectedRoutes';
import AccountHome from './components/account/AccountHome';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import Register from './components/authentication/Register';
import Login from './components/authentication/Login';

const App = () => {

  // pour le modal Register
  const [showRegister, setShowRegister] = useState(false);

  // pour le modal Login
  const [showLogin, setShowLogin] = useState(false);


  const setShowRegisterCallback = () => {
    setShowRegister(!showRegister);
  }

  const setShowLoginCallback = () => {
    setShowLogin(!showLogin);
  }

  useEffect(() => {

    /*firebaseApp.auth().onAuthStateChanged(user => {

      console.log("App js onAuthStateChanged user = ", user);
      if (user) {

        userAlreadyAuthenticated({
            email: user.email,
            displayName: user.displayName,
            address: user.photoURL,
            uid: user.uid
        });
      }

    });*/    

  });

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <Fragment>
            <Navbar setShowRegisterCallback={setShowRegisterCallback} setShowLoginCallback={setShowLoginCallback}/>
            <Register visible={showRegister} closeModalCallback={setShowRegisterCallback}/>
            <Login visible={showLogin}  closeModalCallback={setShowLoginCallback}/>
            <section className="container">
              <Switch>
                <Route exact path="/" render={() => (<Redirect to="/home"/>)}/>
                <Route exact path="/home" component={Home} />
                <Route exact path="/shop" component={Shop} />
                <Route exact path="/cart" component={CartDetail} />
                <Route exact path="/about" component={About} />
                <Route exact path="/customers" component={Customers} />
                <Route exact path="/contact" component={Contact} />
                <ProtectedRoutes setShowLogin={setShowLogin}  exact path="/login" component={Home} />
                <Route exact path="/adminjdio" component={Admin} />
                <Route exact path="/admin/product/:id" component={Admin} />
                <Route exact path='/accountHome' component={AccountHome}/>
                <Route component={Home} />
              </Switch>
            </section>
          </Fragment>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  )
};

export default App;
