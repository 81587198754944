
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const EMAIL_IN_PROGRESS = 'EMAIL_IN_PROGRESS';
export const EMAIL_SEND_OK = 'EMAIL_SEND_OK';
export const EMAIL_SEND_FAIL = 'EMAIL_SEND_FAIL';

export const SAVE_MESSAGE = 'SAVE_MESSAGE';
export const GET_ALL_MESSAGE = 'GET_ALL_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';

export const LOAD_CATEGORIES_IN_PROGRESS = 'LOAD_CATEGORIES_IN_PROGRESS';
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_CATEGORIES_FAIL = 'LOAD_CATEGORIES_FAIL';
export const ADD_CATEGORY_IN_PROGRESS = 'ADD_CATEGORY_IN_PROGRESS';
export const ADD_CATEGORY_OK = 'ADD_CATEGORY_OK';
export const ADD_CATEGORY_FAIL = 'ADD_CATEGORY_FAIL';
export const UPDATE_CATEGORY_IN_PROGRESS = 'UPDATE_CATEGORY_IN_PROGRESS';
export const UPDATE_CATEGORY_OK = 'UPDATE_CATEGORY_OK';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';
export const DELETE_CATEGORY_IN_PROGRESS = 'DELETE_CATEGORY_IN_PROGRESS';
export const DELETE_CATEGORY_OK = 'DELETE_CATEGORY_OK';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';
export const DELETE_CATEGORY_RESET = 'DELETE_CATEGORY_RESET';

export const ADD_PRODUCT_IN_PROGRESS = 'ADD_PRODUCT_IN_PROGRESS';
export const ADD_PRODUCT_OK = 'ADD_PRODUCT_OK';
export const ADD_PRODUCT_FAIL = 'ADD_PRODUCT_FAIL';
export const UPDATE_PRODUCT_IN_PROGRESS = 'UPDATE_PRODUCT_IN_PROGRESS';
export const UPDATE_PRODUCT_OK = 'UPDATE_PRODUCT_OK';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';
export const UPDATE_PRODUCT_CHECKBOX = 'UPDATE_PRODUCT_CHECKBOX';
export const FETCH_PRODUCT_IN_PROGRESS = 'FETCH_PRODUCT_IN_PROGRESS';
export const FETCH_PRODUCT_BY_ID = 'FETCH_PRODUCT_BY_ID';
export const FETCH_PRODUCT_OK = 'FETCH_PRODUCT_OK';
export const FETCH_PRODUCT_FAIL = 'FETCH_PRODUCT_FAIL';
export const DELETE_PRODUCT_IN_PROGRESS = 'DELETE_PRODUCT_IN_PROGRESS';
export const DELETE_PRODUCT_OK = 'DELETE_PRODUCT_OK';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';
export const DELETE_PRODUCT_RESET = 'DELETE_PRODUCT_RESET';
export const FETCH_PAGINATION_PRODUCTS = 'FETCH_PAGINATION_PRODUCTS';

export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const SEARCH_PRODUCTS_IN_PROGRESS = 'SEARCH_PRODUCTS_IN_PROGRESS';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';

export const REGISTER_USER_IN_PROGRESS = 'REGISTER_USER_IN_PROGRESS';
export const REGISTER_USER_OK = 'REGISTER_USER_OK';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const REGISTER_RESET_OK = 'REGISTER_RESET_OK';
export const USER_ALREADY_AUTHENTICATED = 'USER_ALREADY_AUTHENTICATED';
export const USER_LOGOUT = 'USER_LOGOUT';
export const LOGIN_USER_IN_PROGRESS = 'LOGIN_USER_IN_PROGRESS';
export const LOGIN_USER_OK = 'LOGIN_USER_OK';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const LOGIN_RESET_OK = 'LOGIN_RESET_OK';
export const UPDATE_USER_IN_PROGRESS = 'UPDATE_USER_IN_PROGRESS';
export const UPDATE_USER_OK = 'UPDATE_USER_OK';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const FETCH_ADDRESS_LOCATIONS_OK = 'FETCH_ADDRESS_LOCATIONS_OK';