import React, {useState, Fragment} from 'react';
import Collapsible from 'react-collapsible';
import LocalizationHelper from '../../../helpers/LocalizationHelper';
import Localization from './../../../helpers/Localization';
import { updateCategory, deleteCategory } from '../../../redux/actions/categoryAction';
import { connect } from 'react-redux';

const CategoryListItem = ({
    
    category, 
    nbrProducts, 
    language,
    updateInProgress,
    updateOk,
    updateFailed,
    deleteInProgress,
    deleteFailed,
    idCategoryUpdated,
    idCategoryDeleted,
    updateCategory,
    deleteCategory

}) => {

    const [nameFR, setNameFR] = useState(category.nameFR);
    const [nameEN, setNameEN] = useState(category.nameEN);

    const name = language === 'fr' ? category.nameFR : category.nameEN;

        /**
     * 
     * Permet la transition ouverture div de suppression catégorie
     * 
     */
    const openDeleteCategory = categoryId => {

        const wrapper = document.getElementById("idCategoryDeleteDiv" + categoryId);
        wrapper.classList.toggle("delete-category-div-open");
    }

    /**
     * 
     * Modification de la categorie
     * 
     * @param {*} e 
     */
    const onSubmit = e => {

        e.preventDefault();
        const categoryModif = {
            _id: category._id,
            nameFR: nameFR,
            nameEN: nameEN,
            idCat: category.idCat
        }

        console.log("categoryModif = ", categoryModif);
        updateCategory(categoryModif);
    }

    return (
        <div className="category-list os-animation fadeInUpBig">
            <div className="category-item mt-4" key={category._id}>
                <div className="row col-12">
                    <div  className="col-10 mt-2">
                        <span className="ml-2 text-muted">Nom : </span>
                        <span className="category-text ">{name}</span>
                    </div>
                    {nbrProducts === 0 && (
                        <div className="action-icon col-2 mt-2">
                            <button className="delete-button" onClick={() => openDeleteCategory(category._id)}>
                                <i className="fas fa-trash-alt fa-lg text-danger ml-2 mr-2 icon"></i>
                            </button>
                        </div>
                    )}

                </div>

                <div id={`idCategoryDeleteDiv${category._id}`} className="button-confirm-delete mt-2 delete-category-div-close ">
                    <button onClick={e => deleteCategory(category._id)} className="btn btn-primary btn-sm">
                        <LocalizationHelper text="delete"/>
                    </button>
                    <button onClick={e => openDeleteCategory(category._id)} className="btn btn-danger btn-sm ml-2 mr-2">
                        <LocalizationHelper text="cancel"/>
                    </button>
                </div>

                {deleteInProgress && idCategoryDeleted === category._id  && (
                    <div className="d-flex flex-column align-items-end mr-2 mt-2">
                        <div className="">
                            <span className="callCloudFunctionMessageOK blink_me">
                                <LocalizationHelper text="categoryDeleteInProgress"/> 
                            </span>
                        </div>
                        <div className="text-center">
                            <img className="spinner" src={require('../../../img/spinner.gif')} alt="" />
                        </div>    
                    </div> 
                )}
                {!deleteInProgress && idCategoryDeleted === category._id  && deleteFailed && (
                                
                    <div className="d-flex flex-column align-items-end mr-2 mt-2">
                        <span className="callCloudFunctionMessageNOK ml-1">
                            <LocalizationHelper text="categoryDeleteNOK"/> 
                        </span>
                    </div>
                )}
                <div className="row col-12">
                    <div  className="col-12">
                        <span className="category-text-product text-muted ml-2">
                            <LocalizationHelper text="categoryLabelProducts"/> :
                        </span>
                        <span className="category-text-product ml-2">{nbrProducts}</span>
                    </div>
                </div>

            </div>
            <div style={{marginLeft: "20px", marginRight: "20px", width: "80%"}}>
                <Collapsible trigger={Localization.getTextLocalization("categoryEditTitle", language)}>
                    <div className="category-edit-block">
                        <form className="form" onSubmit={e => onSubmit(e)}>
                            <div className="form-group">
                                <div className="column mt-3">
                                    <div>
                                        <label className="category-edit-label ml-2 mt-2">
                                            <LocalizationHelper text="categoryEditNameFrench"/>
                                        </label>
                                        <input  
                                            required type="text" className="form-control category-edit-input"
                                            name="nameFR"
                                            value={nameFR} 
                                            onChange={e => setNameFR(e.target.value)} 
                                            maxLength="30"/>
                                    </div>
                                </div>      
                                <div className="column mt-3">
                                    <div>
                                        <label className="category-edit-label ml-2 mt-2">
                                            <LocalizationHelper text="categoryEditNameEnglish"/>
                                        </label>
                                        <input  
                                            required type="text" className="form-control category-edit-input"
                                            name="nameEN"
                                            value={nameEN} 
                                            onChange={e => setNameEN(e.target.value)} 
                                            maxLength="30"/>
                                    </div>
                                </div>                 
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-success mt-3 mb-4">
                                    <LocalizationHelper text="modify"/>    
                                </button>
                            </div>
                            <div className="row mb-3 justify-content-center">
                            {updateInProgress && idCategoryUpdated === category._id && (
                                <div className="column">
                                    <div className="text-center">
                                        <span className="callCloudFunctionMessageOK blink_me">
                                            <LocalizationHelper text="categoryUpdateInProgress"/> 
                                        </span>
                                    </div>
                                    <div className="text-center">
                                        <img className="spinner" src={require('../../../img/spinner.gif')} alt="" />
                                    </div>    
                                </div>    
                            )}
            
                            {!updateInProgress && idCategoryUpdated === category._id && updateOk && (
                                <Fragment>
                                    <span className="callCloudFunctionMessageOK text-center">
                                        <LocalizationHelper text="categoryUpdateOK"/> 
                                    </span>
                                </Fragment>
                            )}
            
                            {!updateInProgress && idCategoryUpdated === category._id && updateFailed && (
                                
                                <Fragment>
                                    <span className="callCloudFunctionMessageNOK ml-1">
                                        <LocalizationHelper text="categoryUpdateNOK"/> 
                                    </span>
                                </Fragment>
                            )}
            
                        </div>
                        </form>
                    </div>
                </Collapsible>
            </div>
            <div className="" style={{height: "1px", backgroundColor: "antiquewhite"}}>

            </div>
        </div>
    )
}

const mapStateToProps = state => ({

    idCategoryUpdated: state.categories.idCategoryUpdated,
    idCategoryDeleted: state.categories.idCategoryDeleted,
    updateInProgress: state.categories.updateInProgress,
    updateOk: state.categories.updateOk,
    updateFailed: state.categories.updateFailed,
    deleteInProgress: state.categories.deleteInProgress,
    deleteFailed: state.categories.deleteFailed,
});

export default connect(mapStateToProps, {updateCategory, deleteCategory})(CategoryListItem);