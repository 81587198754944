import React, {Fragment, useEffect} from 'react';
import { connect } from 'react-redux';
import '../../../css/admin.css';
import '../../../css/category-item.css';
import LocalizationHelper from './../../../helpers/LocalizationHelper';
import Localization from './../../../helpers/Localization';
import { loadCategories, resetFlagDelete } from '../../../redux/actions/categoryAction';
import CategoryListItem from './CategoryListItem';
import { fetchProducts } from './../../../redux/actions/productAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddCategory from './AddCategory';

const ManageCategories = ({
    language, 
    categories, 
    products,
    loadingInProgress,
    loadingFailed,
    deleteOk,
    loadCategories,
    fetchProducts,
    resetFlagDelete,
}) => {

    let nbrProductsPerCategory = {};

    useEffect (() => {

        const fetchData = async () => {

            if (products.length === 0) {
                await fetchProducts();
            }

            if (categories.length === 0) {
                await loadCategories(language);
            }
        }
  
        fetchData();
        console.log("categories = ", categories);
        setTimeout(() => {
            resetFlagDelete();
        }, 2000)
        
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
            fetchProducts, loadCategories, products.length, 
            categories.length, language, resetFlagDelete
         ]);

      /**
     * 
     * Calcule le nombre de produits par catégorie
     * 
     */
    const calcNbrProducts = () => {

        if (products && categories) {
          categories.forEach(category => {
            const count = products.length > 0 ? products.reduce((acc, cur) => cur.category.idCat === category.idCat ? ++acc : acc, 0) : 0;
            nbrProductsPerCategory = {...nbrProductsPerCategory, [category.idCat]: count}
          });
        }
      }

    /**
     * 
     * Affiche toaster success pour suppession produit
     * 
     */
    const showToastSuccess = () => {
        toast.success(Localization.getTextLocalization("categoryDeleteOK", language), {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
            });
    }
      /**
       * 
       * Rendu de la liste des catégories
       * 
       */
      const renderCategories = () => {
        
        calcNbrProducts();

        return categories.map(category => {

            if (category.idCat > 0) {
 
                return <CategoryListItem 
                    category={category}
                    nbrProducts= {nbrProductsPerCategory[category.idCat]} 
                    language={language} 
                    key={category.idCat} />               
            } else {
                return null;
            }
        })
      }

    /**
     * 
     * Permet la transition ouverture div d'ajout catégorie
     * 
     */
    const openAddCategory = () => {

        const wrapper = document.getElementById("idCategoryAddDiv");
        wrapper.classList.toggle("add-category-div-open");

        const formWrapper = document.getElementById("idFormAddCategory");
        if (wrapper.classList.contains('add-category-div-open')) {
            formWrapper.classList.remove('display-none');
            formWrapper.classList.add('display-block');
        } else {
            setTimeout(() => {
                formWrapper.classList.remove('display-block');
                formWrapper.classList.add('display-none');   
            }, 500)
        
        }
    }

    return (

        <Fragment>

            <div className="os-animation fadeInLeftSpeed mt-3">

                <p className="text-header-form" >
                    <LocalizationHelper text="manageCategoriesTitle"/>
                </p>
                <p className="text-explain-delete" >
                    <LocalizationHelper text="categoryExplainDelete"/>
                </p>
                <p>
                    <span className="text-info">
                        <LocalizationHelper text="categoryAddText"/>
                    </span>
                    <button className="button-add" onClick={e => openAddCategory()}>
                        <i className="fas fa-plus-circle fa-2x text-info"></i>
                    </button>
                </p>

                <div id="idCategoryAddDiv" className="add-category-div-close">
                    <span>
                        <AddCategory 
                            openAddCategory={openAddCategory} 
                            language={language} />
                    </span>
                </div>

                {loadingInProgress && (
                    <Fragment>
                        <div className="bg-light shop p-4">
                        <span className="callCloudFunctionMessageOK blink_me">
                            <LocalizationHelper text="fetchCategoriesInProgress"/> 
                        </span>
                        <img className="spinner" src={require('../../../img/spinner.gif')} alt="" />
                        </div>
                    </Fragment> 
                )}  
                <div className="mb-4">
                     {renderCategories()}
                </div>

                {!loadingInProgress && loadingFailed && (
                    
                    <div className="mt-3">
                        <span className="callCloudFunctionMessageNOK ml-1">
                            <LocalizationHelper text="fetchCategoriesNOK"/> 
                        </span>
                    </div>
                )}
                {deleteOk && (
                    
                    <Fragment>
                        {showToastSuccess()}
                        <ToastContainer />
                    </Fragment>
                )}
            </div>

        </Fragment>
    )
    
}

const mapStateToProps = state => ({
    language: state.config.language, 
    categories: state.categories.categories,
    products: state.products.products,
    loadingInProgress: state.categories.loadingInProgress,
    loadingFailed: state.categories.loadingFailed,
    deleteOk: state.categories.deleteOk
});

export default connect(
    mapStateToProps, 
    {loadCategories, fetchProducts, resetFlagDelete})(ManageCategories);