
import { REGISTER_USER_IN_PROGRESS, REGISTER_USER_OK, USER_ALREADY_AUTHENTICATED,
         REGISTER_USER_FAILED, REGISTER_RESET_OK, USER_LOGOUT, LOGIN_USER_IN_PROGRESS, 
         LOGIN_USER_OK, LOGIN_USER_FAILED, LOGIN_RESET_OK, UPDATE_USER_IN_PROGRESS,
         UPDATE_USER_FAILED, UPDATE_USER_OK } from './../actions/types';

const initialState = {

    isAuthenticated: false,
    userDatas: undefined,
    registerInProgress: false,
    registerOk: false,
    registerFailed: false,
    loginInProgress: false,
    loginOk: false,
    loginFailed: false,
    updateUserInProgress: false,
    updateUserOk: false,
    updateUserFailed: false,
    message: ''
}

export default function(state = initialState, action) {

    switch(action.type) {

        case REGISTER_USER_IN_PROGRESS:
            return {...state, registerInProgress: true, registerOk: false, registerFailed: false};
        
        case REGISTER_USER_OK:
            return {...state, userDatas: action.payload.user, message: action.payload.message, isAuthenticated: true, registerInProgress: false, registerOk: true, registerFailed: false};
        
        case REGISTER_USER_FAILED:
            return {...state, message: action.payload, registerInProgress: false, registerOk: false, registerFailed: true};
        
        case REGISTER_RESET_OK:
            return {...state, registerOk: false};

        case USER_ALREADY_AUTHENTICATED: 
            console.log('USER_ALREADY_AUTHENTICATED payload : ', action.payload);
            return {...state, isAuthenticated: true, userDatas: action.payload};
        
        case USER_LOGOUT:
            return {...state, isAuthenticated: false, userDatas: undefined};

        case LOGIN_USER_IN_PROGRESS:
            return {...state, loginInProgress: true, loginOk: false, loginFailed: false};

        case LOGIN_USER_OK:
            return {...state, isAuthenticated: true, message: action.payload, loginInProgress: false, loginOk: true, loginFailed: false};
        
        case LOGIN_USER_FAILED:
            return {...state, isAuthenticated: false, message: action.payload, loginInProgress: false, loginOk: false, loginFailed: true};
        
        case LOGIN_RESET_OK: 
            return {...state, message: '', loginInProgress: false, loginOk: false, loginFailed: false};

        case UPDATE_USER_IN_PROGRESS:
            return {...state, updateUserInProgress: true, updateUserOk: false, updateUserFailed: false};

        case UPDATE_USER_OK:
            return {...state, userDatas: action.payload.user, message: action.payload.message, updateUserInProgress: false, updateUserOk: true, updateUserFailed: false};

        case UPDATE_USER_FAILED:
            return {...state, userDatas: action.payload.user, message: action.payload.message, updateUserInProgress: false, updateUserOk: false, updateUserFailed: true};

        default:
            return state;
    }

};