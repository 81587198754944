import React from 'react';

const Customers = () => {
    return (
        <div>
            Customers
        </div>
    )
}

export default Customers;
