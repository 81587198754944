import React from 'react';
import LocalizationHelper from '../helpers/LocalizationHelper';
import '../css/about.css';

const About = () => {
    return (
        <section className="about">

            <div className="row justify-content-center">
                <span className="page-about-header text-center os-animation rotateInDownLeft">
                    <span className="page-about-header-Dancing">
                        <LocalizationHelper text="aboutHeaderTitle_1"/>
                    </span>
                    <span className="page-about-header-Montserrat">
                        <LocalizationHelper text="aboutHeaderTitle_2"/>
                    </span>
                </span>
            </div>
            <div className="row text-light text-center text-md-right  align-items-center justify-content-center">
                <div className="about-card about-anim1 p-2 display-row">
                    <div className="row">
                        <span className="col-2">
                            <div className="os-animation fadeInDownBig" style={{opacity: 1}}>
                                <img src={require('../img/customer2.jpeg')}  className="about-avatar-img rounded-circle" alt="" />
                            </div>
                            <p className="about-name mt-2">Jacques DIO</p>
                        </span>
                        <span className="col-10">
                            <p className="about-description text-left">Coriolano illi rem iuvare Maelium amicos ferre id amor Numne debeat num in amicos progredi cum num ferre.</p>
                            <p className="about-description text-left">Reducere quae cum factitasse ut truculenti propositum potius ad fortunas actibus : </p>
                            <p className="about-description list-space  text-left">- Cave cave ut ut ipsum.</p>
                            <p className="about-description list-space  text-left">- Nullam essent uterque amicitiis honoris uterque honoris.</p>
                            <p className="about-description list-space  text-left">- Cave cave ut ut ipsum.</p>
                        </span>
                    </div>                   
                </div>

                {/*
                <div className="about-card about-anim2 p-2 display-row">
                    <div className="row">
                        <span className="col-2">
                            <div className="os-animation fadeInDownBig" style={{opacity: 1}}>
                                <img src={require('../img/customer1.jpeg')}  className="about-avatar-img rounded-circle" alt="" />
                            </div>
                            <p className="about-name mt-2">Marie DELANOE</p>
                        </span>
                        <span className="col-10">
                            <p className="about-description text-left">Coriolano illi rem iuvare Maelium amicos ferre id amor Numne debeat num in amicos progredi cum num ferre.</p>
                            <p className="about-description text-left">Reducere quae cum factitasse ut truculenti propositum potius ad fortunas actibus humanitatisque quae stimulos praeceps feminea obstinatum suadendo Maximini in.</p>
                        </span>
                    </div>
                </div>
                */}
                
                <div className="about-card about-anim1 p-2 display-column">
                    <div className="column">
                        <span className="text-right">
                            <div className="os-animation fadeInDownBig" style={{opacity: 1}}>
                                <img src={require('../img/customer2.jpeg')}  className="about-avatar-img rounded-circle" alt="" />
                            </div>
                            <p className="about-name text-right">Jacques DIO</p>
                        </span>
                        <span className="text-right">
                            <p className="about-description text-left">Coriolano illi rem iuvare Maelium amicos ferre id amor Numne debeat num in amicos progredi cum num ferre.</p>
                            <p className="about-description text-left" >Reducere quae cum factitasse ut truculenti propositum potius ad fortunas actibus : </p>
                            <p className="about-description list-space text-left">- Cave cave ut ut ipsum.</p>
                            <p className="about-description list-space text-left">- Nullam essent uterque amicitiis honoris uterque honoris.</p>
                            <p className="about-description list-space text-left">- Cave cave ut ut ipsum.</p>
                        </span>
                    </div>                   
                </div>
                <div className="about-card about-anim1 p-2 display-column">
                    <div className="column">
                        <span className="text-right">
                            <div className="os-animation fadeInDownBig" style={{opacity: 1}}>
                                <img src={require('../img/customer1.jpeg')}  className="about-avatar-img rounded-circle" alt="" />
                            </div>
                            <p className="about-name text-right">Marie Test-Nom-Long</p>
                        </span>
                        <span className="">
                            <p className="about-description text-left">Coriolano illi rem iuvare Maelium amicos ferre id amor Numne debeat num in amicos progredi cum num ferre.</p>
                            <p className="about-description text-left">Reducere quae cum factitasse ut truculenti propositum potius ad fortunas actibus humanitatisque quae stimulos praeceps feminea obstinatum suadendo Maximini in.</p>
                        </span>
                    </div>                   
                </div>
                
            </div>
        </section>
    )
}

export default About;
