import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import UtilsHelper from '../../helpers/UtilsHelper';

const CategoryNavigation = ({language, manage, categories, products, resetSearch}) => {

    let categoryAll = '';
    let nbrProductsPerCategory = {};
    const CATEGORY_NEW_INDEX = -1;
    const CATEGORY_INACTIVE_INDEX = -2;
    const CATEGORY_SOLD_INDEX = -3;
    const CATEGORY_RESERVED_INDEX = -4;
    const CATEGORY_ALL_INDEX = -5;

    //const CATEGORY_ALL_NAME_FR = "Tout";
    //const CATEGORY_ALL_NAME_EN = "All";

    /**
     * 
     * Chargement script jquery pour différents effets
     */
    useEffect(() => {

        toggleSeparatorClass();

        $(window).resize(toggleSeparatorClass);
        
        /** lorsqu'on fait un re-render de la page, par exemple après Search, on revient sur Catégorie All */
        /*let categoryName = '';
        if (language === 'fr') {
          categoryName = CATEGORY_ALL_NAME_FR;
        } else {
          categoryName = CATEGORY_ALL_NAME_EN;
        }

        filterProducts(categoryName);*/
    });


    /**
     * 
     * Calcule le nombre de produits par catégorie
     * 
     */
    const calcNbrProducts = () => {

      if (products && categories) {

        categories.forEach(category => {
          const count = products.length > 0 ? products.reduce((acc, cur) => cur.category.idCat === category.idCat ? ++acc : acc, 0) : 0;
          nbrProductsPerCategory = {...nbrProductsPerCategory, [category.idCat]: count}
        });

        // categorie ALL
        nbrProductsPerCategory = {...nbrProductsPerCategory, [CATEGORY_ALL_INDEX]: products.length};

        // category NEW
        const countNew = products.length > 0 ? products.reduce((acc, cur) => cur.news === true ? ++acc : acc, 0) : 0;
        nbrProductsPerCategory = {...nbrProductsPerCategory, [CATEGORY_NEW_INDEX]: countNew};
      
        // category INACTIVE
        const countInactive = products.length > 0 ? products.reduce((acc, cur) => cur.active === false ? ++acc : acc, 0) : 0;
        nbrProductsPerCategory = {...nbrProductsPerCategory, [CATEGORY_INACTIVE_INDEX]: countInactive};
      
        // category SOLD
        const countSold = products.length > 0 ? products.reduce((acc, cur) => cur.sold === true ? ++acc : acc, 0) : 0;
        nbrProductsPerCategory = {...nbrProductsPerCategory, [CATEGORY_SOLD_INDEX]: countSold};

        // category RESERVED
        const countReserved = products.length > 0 ? products.reduce((acc, cur) => cur.reserved === true ? ++acc : acc, 0) : 0;
        nbrProductsPerCategory = {...nbrProductsPerCategory, [CATEGORY_RESERVED_INDEX]: countReserved};
        

      }

    }

    /**
     * 
     * positionnement d'un séparateur suivant largeur écran
     * 
     */
    const toggleSeparatorClass = () => {
        if ($( window ).width() <= 450) {
          $(".list-inline").siblings().removeClass("list-inline-item");
            $(".separator").removeClass("separator_show");
            $(".separator").addClass("separator_hide");
        } else {
            $(".list-inline").siblings().addClass("list-inline-item");
            $(".separator").addClass("separator_show");
            $(".separator").removeClass("separator_hide");
        }
    }

    /**
     * 
     * Filtrage des products suivant Category sélectionnée
     * 
     * @param {*} value 
     */
    const filterProducts = (value) => {

        const filterValue = UtilsHelper.sanitizeCatagoryName(value);
        if (filterValue === UtilsHelper.sanitizeCatagoryName(categoryAll)) {
            $('.filter').show(300);
        } else {
            $('.filter').not('.' + filterValue).hide(300);
            $('.filter').filter('.' + filterValue).show(300);

            // affiche/masque text pas de produits dans catégorie
            if ($('.' + filterValue).length === 0) {
              $('#textNoProducts').removeClass('d-none');
            } else {
              $('#textNoProducts').addClass('d-none');
            }
        }
        
        $(`li[data-filter=${filterValue}]`).each(function() {
           $(this).addClass('active-item').siblings().removeClass('active-item');
        });
      }

      /**
       * 
       * Fonction de rendu des catégories
       * 
       * Il faut traiter suivant localisation
       * Il faut aussi positionner la première catégorie 'All' comme sélectionnée
       * 
       */
    const renderCategoriesNavigation = () => {

        calcNbrProducts();
        const idCatInactive = manage ? -100 : CATEGORY_INACTIVE_INDEX;
        return categories.filter(category => category.idCat !== 0 && category.idCat !== idCatInactive).map((category, index) => {


          // localisation category
          let categoryName = '';
          if (language === 'fr') {
            categoryName = category.nameFR;
          } else {
            categoryName = category.nameEN;
          }
  
          // class active sur première category (all) + category All
          let className = '';
          if (index === 0) {
            className = "list-inline-item gallery-list-item active-item";
            categoryAll = categoryName;
          } else {
            className = "list-inline-item gallery-list-item";
          }

          // premier séparteur
          let firstSeparator = '';
          if (index === 0) {
            firstSeparator = <span className="mx-md-3 mx-3 text-muted separator separator_show">/</span>
          }
  
          return (
            <li key={index} className={className} 
                onClick={() => filterProducts(categoryName)} 
                data-filter={UtilsHelper.sanitizeCatagoryName(categoryName)}>
  
                {firstSeparator} {categoryName} <span className="categoty-nbt-items">({nbrProductsPerCategory[category.idCat]})</span> <span className="mx-md-3 mx-3 text-muted separator separator_show">/</span>
            
              </li>
          )
        });
      }

    return (
        <ul className="list-inline text-center text-uppercase font-weight-bold my-4">
            {renderCategoriesNavigation()}

            {resetSearch && filterProducts(categoryAll)}

        </ul>
    )

}

const mapStateToProps = state => ({
  resetSearch: state.categories.resetSearch
});

export default connect(mapStateToProps, {})(CategoryNavigation);
