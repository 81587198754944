
import {ADD_TO_CART, REMOVE_FROM_CART} from '../actions/types';
import { constants } from '../../configuration/constants';

const intialState = {
    activeCart: constants.ACTIVE_CART,
    nbrProducts: 0,
    products: []
}

export default function(state = intialState, action) {

    const {type, payload} = action;

    switch(type) {

        case ADD_TO_CART:

            let finalProducts = [...state.products];
            finalProducts.push(payload);

            return {
                    ...state,
                    products: finalProducts, 
                    nbrProducts: state.nbrProducts + 1
                };

        case REMOVE_FROM_CART: {

            const finalProductsCart = state.products
                                          .filter(product => product._id !== payload._id);

            return {
                    ...state,
                    products: finalProductsCart, 
                    nbrProducts: state.nbrProducts - 1
                };
        }

        default:
            return state;
    }
}