import axios from 'axios';
import { constants } from '../../configuration/constants';
import { EMAIL_IN_PROGRESS, EMAIL_SEND_OK, EMAIL_SEND_FAIL } from './types';


export const sendMail = (massage) => async dispatch => {

    dispatch({type: EMAIL_IN_PROGRESS});

    try {
        
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body =  JSON.stringify(massage);

        await axios.post(constants.URL_CLOUD_MAIL, body, config);

        dispatch({
            type: EMAIL_SEND_OK
        });

    } catch (error) {

        console.error('error sendMail = ', error);

        dispatch({
            type: EMAIL_SEND_FAIL
        });
    }

}