import React from 'react';
import Modal from "react-animated-modal";
import ProductListItem from './ProductListItem';

const ModalProductPreview = ({visible, language, productDatas, fileImage, filesImages, closeModalCallback}) => {

    const productToPreview = {...productDatas, imageUrl: filesImages};

    return (
            <Modal
                visible={visible}
                closemodal={() => closeModalCallback()}
                type="zoomIn"
            >
                <div className="mt-5">
                    <ProductListItem language={language} product={productToPreview} preview={true} />
                </div>

            </Modal>
    )
}

export default ModalProductPreview;
