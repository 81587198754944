import axios from 'axios';
import { constants } from '../../configuration/constants';

export const saveMessage = ({name, email, message}) => async dispatch => {

    console.log('SAVE MESSAGE BEGIN');
    
    try {
        
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const body =  JSON.stringify({name, email, message});

        await axios.post(constants.URL_CLOUD_SAVE_MESSAGE, body, config);

        console.log('SAVE MESSAGE OK');

    } catch (error) {
        console.error('error save message = ', error);
    }
}