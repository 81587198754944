import React, {Fragment} from 'react';
import '../../../css/admin.css';
import LocalizationHelper from './../../../helpers/LocalizationHelper';
import Shop from '../../shop/Shop';

const ManageProducts = () => {

    return (

        <Fragment>

            <div className="os-animation fadeInLeftSpeed mt-3">
                <p className="text-header-form" >
                    <LocalizationHelper text="manageProductsTitle"/>
                </p>
            </div>

            <Shop manage={true} />

        </Fragment>
    )
    
}

export default ManageProducts;
