import React from 'react';

function Orders() {
    return (
        <div className="os-animation fadeInUp mt-3 mb-4">
            Orders
        </div>
    )
}

export default Orders
