import React from 'react';
import { connect } from 'react-redux';
import '../../css/cart-detail.css';
import LocalizationHelper from '../../helpers/LocalizationHelper';
import CartDetailProducts from './CartDetailProducts';
import { NavLink } from 'react-router-dom';

function CartDetail({
    language,
    products
}) {

    return (
        <div className="bg-light cart-detail os-animation fadeInUp" >
            <div className="row">
                <div className="col text-center pt-4 mx-2">
                    <h1 className="text-secondary text-uppercase font-italic common-title"><LocalizationHelper text="cartWelcomeTitle"/></h1>
                </div>           
            </div>  
            {products.length > 0 &&  <CartDetailProducts products={products} language={language} />}
            {products.length === 0 &&  
            
                <div className="d-flex flex-column text-center cart-no-products-container">
                    <span className="cart-no-products mt-4">
                        <LocalizationHelper text="cartNoProducts"/>
                    </span>
                    <div style={{cursor: "pointer"}} className="mt-2 mb-2">
                        <NavLink to="/shop" activeClassName="active" className="nav-link">
                            <span className="cart-no-product-link">
                                <LocalizationHelper text="cartNoProductsShop"/>
                                <i className="fas fa-shopping-bag fa-2x ml-2"></i>
                            </span>
                        </NavLink>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        products: state.shoppingCart.products,
        language: state.config.language,
    }
}

export default connect(mapStateToProps, {})(CartDetail);
