import React from 'react';
import '../../../css/admin.css';
import LocalizationHelper from './../../../helpers/LocalizationHelper';

const Messages = () => {

    return (
        <div className="os-animation fadeInLeftSpeed mt-3">
        <p className="text-header-form" >
            <LocalizationHelper text="messagesTitle"/>
        </p>
    </div>
    )
}

export default Messages;
