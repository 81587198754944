import React from 'react';
import ReactHover from 'react-hover';
import { NavLink } from 'react-router-dom';
import CartHover from '../Cart/CartHover';

const optionsCursorTrueWithMargin = {
    followCursor: false,
    shiftX: -20,
    shiftY: 20
  }

function ShoppingIcon({clickHandler, nbrProducts}) {

    let width = window.innerWidth;

    // condition affichage Hover
    // - écran assez grand
    // - pas sur l'écran /cart
    let showReactHover = width > 990 && window.location.pathname !== '/cart' ? true : false;
    return (
        <ReactHover options={optionsCursorTrueWithMargin}>
            <ReactHover.Trigger type='trigger'>
                <li onClick={() => clickHandler()} className="nav-item clickCollapse">
                    <NavLink to="/cart" activeClassName="active" className="nav-link ml-2 mt-1 menu-item">
                        <div className="shopping-container mt-1">   
                                <i className="fas fa-shopping-cart"></i>
                            <div className="shopping-cart-badge text-center">
                                {nbrProducts}
                            </div>
                        </div>
                    </NavLink>
                </li>    
            </ReactHover.Trigger>
            {showReactHover && nbrProducts > 0 &&
                <ReactHover.Hover type='hover'>
                    <CartHover/>
                </ReactHover.Hover>
            }
        </ReactHover>  
    )
}

export default ShoppingIcon;
