import React, {useEffect, Fragment} from 'react';
import { connect } from 'react-redux';
import { fetchProducts } from '../../redux/actions/productAction';
import { loadCategories } from '../../redux/actions/categoryAction';
import '../../css/shop.css';
import LocalizationHelper from '../../helpers/LocalizationHelper';
import CategoryNavigation from './CategoryNavigation';
import ProductsList from '../admin/Product/ProductsList';
import $ from 'jquery'; 
import Search from './Search';
import { constants } from '../../configuration/constants';

const Shop = ({
  loadCategories,
  fetchProducts,
  processInProgress, 
  loadProductsOk, 
  categories, 
  products, 
  language,
  manage,
  lastUpdateProductsList,
  resultOfSearch,
  deleteOk
}) => {

    
    /**
     * 
     * Chargement script jquery pour différents effets
     */
    useEffect (() => {

      const fetchData = async () => {

        await fetchProducts(manage);
        await loadCategories(language);
      }
      
      const updateDelayOk = (Date.now() - lastUpdateProductsList)/1000 > constants.UPDATE_DELAY_TIME;

      if ((updateDelayOk || manage || products.length === 0) && !resultOfSearch && !deleteOk) {
        fetchData();
      }

    //}, [fetchProducts, loadCategories, manage, language, products.length, lastUpdateProductsList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manage, language, products.length, resultOfSearch]);

    /**
     * 
     * Permet d'attaches l'événement click sur le FAB de scroll ver
     * 
     */
    const attachClickToButtonTop = () => {

      $("#buttonShopTop").click(function() {

        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })

      });
    }

    const styleDisplay = loadProductsOk ? 'block' : 'none';

    return (

      <Fragment>

        {processInProgress && products.length === 0 && (
          <Fragment>
              <div className="bg-light shop p-4 os-animation fadeInUp">
              <span className="callCloudFunctionMessageOK blink_me">
                  <LocalizationHelper text="fetchProductInProgress"/> 
              </span>
              <img className="spinner" src={require('../../img/spinner.gif')} alt="" />
              </div>
          </Fragment> 
        )}   
    
        <div className="bg-light shop os-animation fadeInUp" style={{display: styleDisplay}}>
          <div className="row">
            <div className="col text-center pt-4 mx-2">
              <h1 className="text-secondary text-uppercase font-italic common-title"><LocalizationHelper text="shopWelcome"/></h1>
            </div>           
          </div>

          <Search language={language} manage={manage} products={products} />

          <div className="os-animation fadeInUp">
            <CategoryNavigation categories={categories} 
                                products={products} 
                                manage={manage}
                                language={language} />
          </div>


          <ProductsList products={products} manage={manage} />

          <div id="textNoProducts" className="d-none text-center font-weight-bold py-4 text-white bg-info">
              <LocalizationHelper text="shopNoProducts"/>
          </div>


          {loadProductsOk && (
            <Fragment>
              <button className="btn pmd-btn-fab pmd-ripple-effect btn-primary btn-custom" type="button" id="buttonShopTop">
                <i className="fas fa-arrow-up"></i>
              </button>

              {attachClickToButtonTop()}
            </Fragment>
          )}

        </div>
      </Fragment>
        
    )
}

const mapStateToProps = state => ({
  categories: state.categories.categories,
  products: state.products.products,
  lastUpdateProductsList: state.products.lastUpdateProductsList,
  language: state.config.language,
  loadProductsOk: state.products.processOk,
  processInProgress: state.products.processInProgress,
  resultOfSearch: state.products.resultOfSearch,
  deleteOk: state.products.deleteOk
});

export default  connect(mapStateToProps, 
                        {loadCategories, fetchProducts})(Shop);
