import React, {useState} from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import Collapsible from 'react-collapsible';
import LocalizationHelper from '../../../helpers/LocalizationHelper';
import Localization from '../../../helpers/Localization';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../../../css/product-item.css'
import UtilsHelper from '../../../helpers/UtilsHelper';
import ProductItemShopFooter from './ProductItemShopFooter';
import ProductItemManageFooter from './ProductItemManageFooter';

/**
 * 
 * preview = pour savoir si on affiche le produit en mode preview lors création/modification
 * 
 * manage = pour savoir si on est en mode admin pour modifier/supprimer
 * 
 * @param {*} param0 
 */
const ProductListItem = ({
    product, 
    language, 
    preview, 
    manage
  }) => {

    const [activeProduct, setActiveProduct] = useState(product.active);
    const [soldProduct, setSoldProduct] = useState(product.sold);
    const [newsProduct, setNewsProduct] = useState(product.news);
    const [reservedProduct, setReservedProduct] = useState(product.reserved);

    let width = window.innerWidth;  

    let productTitle = '';
    let productDescription = '';
    let productPrice = '';
    let categoryName = '';
    let categoryNew = '';
    let categoryInactive = '';
    let categorySold = '';
    let categoryReserved = '';
    let currency = ''

    if (language === 'fr') {

        productTitle = product.titleFR;
        productDescription = product.descriptionFR;
        productPrice = product.priceEuro;
        currency = ' €';
        categoryName = product.category ? UtilsHelper.sanitizeCatagoryName(product.category.nameFR) : '';

        categoryNew = newsProduct ? UtilsHelper.sanitizeCatagoryName('Nouveautés') : '';
        categoryInactive = activeProduct ? '' : UtilsHelper.sanitizeCatagoryName('Inactif');
        categorySold = soldProduct ? UtilsHelper.sanitizeCatagoryName('Vendu') : '';
        categoryReserved = reservedProduct ? UtilsHelper.sanitizeCatagoryName('Réservé') : '';

    } else {

        productTitle = product.titleEN;
        productDescription = product.descriptionEN;
        //productPrice = product.priceDollar;
        //currency = ' $';
        productPrice = product.priceEuro;
        currency = ' €';
        categoryName = UtilsHelper.sanitizeCatagoryName(product.category.nameEN);

        categoryNew = newsProduct ? UtilsHelper.sanitizeCatagoryName('New') : '';
        categoryInactive = activeProduct ? '' : UtilsHelper.sanitizeCatagoryName('Inactive');
        categorySold = soldProduct ? UtilsHelper.sanitizeCatagoryName('Sold') : '';
        categoryReserved = reservedProduct ? UtilsHelper.sanitizeCatagoryName('Reserved') : '';
    }

    // détermination de la classe Product avec filtres ${categoryName} ${categoryNew}
    // suivant aussi si on est en mode Preview de la partie Admin (AddProduct => ModalPreview)
    const className = preview ? 
                      `col-12 mx-auto gallery-item  filter ${categoryName} ${categoryNew} ${categoryInactive} ${categorySold} ${categoryReserved}`
                      :
                     `col-lg-4 col-sm-6 col-10 mx-auto gallery-item filter ${categoryName} ${categoryNew} ${categoryInactive} ${categorySold} ${categoryReserved}`
    /**
     * 
     * Fonction de rendu de la liste des images d'un Product
     * 
     * On change le style suivant l'image qui doit être affichée en en-tête
     * On désactive la possibilité de cliquer sur les miniatures on mode Preview
     * 
     */
    const renderImages = () => {

      const imagesArray = product.imageUrl.map((image, index) => {

        let widthDisplay = 'auto';
        let opacityDisplay = '1';
        let className = "img-thumbnail gallery-img";

        if (index !== product.firstImageIndex) {
            widthDisplay = '3rem';
            opacityDisplay = '.7';
            className = "img-thumbnail gallery-img-miniature"
        } 

        // suivant mode preview de AddProduct les attributs peuvent ne pas être renseigné => il en faut fonc par défaut
        const dataLightBox = preview ? 'productId' : product._id;
        const dataTitle = preview ? 'productTitle' : productTitle;

        // consrtruction de l'image à afficher
        const imageToShow = 
          <a href={product.imageUrl[index]} data-lightbox={dataLightBox} data-title={dataTitle}>
            <LazyLoadImage 
              effect="blur"
              src={product.imageUrl[index]} 
              className={className} 
              style={{width: widthDisplay, opacity: opacityDisplay, maxHeight: "15rem"}} alt=""></LazyLoadImage>
          </a> 

        // les miniatures doivent être sous l'image principale
        if (index === product.firstImageIndex) {
          return (
            <div key={index}>
              {imageToShow}    
            </div>
          );
        } else {

          return (
            <span key={index}>
              {imageToShow}    
            </span>   
          );
        }

      });

      imagesArray.forEach(function(item,i){
        if(i === product.firstImageIndex){
          imagesArray.splice(i, 1);
          imagesArray.unshift(item);
        }
      });

      return imagesArray;
    }

    /**
     * 
     * Rendu de la description suivant largeur écran
     * 
     * écran < 500 => on collapse la description
     * 
     */
    const renderDescription = () => {
       
       if (width < 576) {
         return (
          <Collapsible trigger={Localization.getTextLocalization("productShowDescription", language)} className="mt-4">
            <p className="gallery-item-description text-muted">
              <span dangerouslySetInnerHTML={{ __html: productDescription }} />
            
            </p>
          </Collapsible>
         )
       } else {
         return (
          <p className="gallery-item-description text-muted">
            <span dangerouslySetInnerHTML={{ __html: productDescription }} />
          </p>           
         )
       }
    }

    return (
            <div className={className}>

              {soldProduct && (
                <div className="ribbon-sold text-center">
                  <LocalizationHelper text="sold"/>
                  </div>
              )}

              {newsProduct && (
                <div className="ribbon-new text-center">
                  <LocalizationHelper text="new"/>
                  </div>
              )}

              {reservedProduct && (
                <div className="ribbon-reserved text-center">
                  <LocalizationHelper text="reserved"/>
                  </div>
              )}

              {!activeProduct && (
                <div className="ribbon-inactive text-center">
                  <LocalizationHelper text="inactive"/>
                  </div>
              )}
              
              <span className="img-item">
                {renderImages()}
              </span>

              <div>
                       
               {/** 
                <span className="row justify-content-end mr-2 mb-4 date-publication">
                  <LocalizationHelper text="productPublish"/>
                    <Moment format="DD/MM/YYYY">
                        {product.createdOn}
                    </Moment>
                </span>
                */}

                <p className="gallery-item-title text-center mt-4">{productTitle}</p>
                {renderDescription()}

                <span className="row justify-content-end mr-2 mb-4 date-publication">
                    <span className="mr-1">
                      <LocalizationHelper text="productPublish"/>
                    </span>
                    <Moment format="DD/MM/YYYY HH:mm">
                        {product.updatedOn}
                    </Moment>
                </span>

                <div style={{height: "4rem"}}>

                </div>

                <div className="mb-5">
                  <p className="row justify-content-end mb-5 text-success font-weight-bold product-price">
                    <span><NumberFormat value={productPrice} displayType={'text'} format={UtilsHelper.getNumberMaskFormat(productPrice)} /> {currency}</span>
                  </p>
                </div>            

                  {!manage && !soldProduct &&
                    <ProductItemShopFooter product={product}/>
                  }

                  {manage && (
                    <div className="mt-2">
                    <ProductItemManageFooter 
                      product={product} 
                      setActiveProduct={setActiveProduct}
                      setSoldProduct={setSoldProduct}
                      setNewsProduct={setNewsProduct} 
                      setReservedProduct={setReservedProduct}/>
                      </div>
                    )
                  }
              
              </div>        
            </div>            
    )
}

export default connect(null, {})(ProductListItem);

